@import ../constants

.ReservationData
  .label
    display: flex
    align-items: center

    svg.badge
      width: 1rem
      height: 1rem
      path
        fill: $black

  .CheckinLabel
    display: block
