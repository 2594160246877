@import ../constants

.FromToTimePicker
  display: flex
  margin-top: 0.5rem

  input
    width: 100%
    padding: 0.3rem 0.5rem
    border: 1px solid $gray-2
    outline: none
    background: white
    -webkit-appearance: none
    border-radius: 0
    font-size: $font-size-normal
    
  input:focus
    background: $blue-text-background
    border: 1px solid $primary

  .from
    flex-basis: 45%
    display: flex

  .icon
    flex-basis: 10%
    display: flex
    justify-content: center
    align-items: center

    svg path
      fill: $gray-5

  .to
    flex-basis: 45%
    display: flex
