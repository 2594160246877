@import ../constants

.TimeslotPicker
  .scroll-vertical::-webkit-scrollbar
    display: none

  .scroll-vertical
    display: flex
    overflow-x: scroll
    scrollbar-width: none
    flex-basis: 100%

    .scroll-item
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      border: 1px solid $gray-2
      margin-right: 0.5rem
      padding: 0.5rem 1rem

      .slot-name
        font-weight: bold
        text-align: center
        white-space: nowrap
        min-width: 4rem

      .slot-time
        text-align: center
        white-space: nowrap
        font-size: $font-size-small
        color: $gray-5

    .active
      border: 1px solid $primary
      color: $primary
      background: $blue-text-background

      .slot-time
        color: $primary

  .no-slots
    font-size: 0.9rem
    padding: 0.25rem 0.5rem
    color: $gray-3

  .custom-slot
    margin: 1rem 1rem 1rem 0

    .subtext
      color: $gray-5
      font-size: $font-size-small

    .inputs
      display: flex
      margin-top: 0.5rem

      input
        width: 100%
        padding: 0.3rem 0.5rem
        border: 1px solid $gray-2
        outline: none
        background: white
        -webkit-appearance: none
        border-radius: 0
        font-size: $font-size-normal

      input:focus
        background: $blue-text-background
        border: 1px solid $primary

      .from
        flex-basis: 45%
        display: flex

      .icon
        flex-basis: 10%
        display: flex
        justify-content: center
        align-items: center
        color: $gray-3

      .to
        flex-basis: 45%
        display: flex
