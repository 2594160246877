@import ../../constants

.InviteCSVForm
  position: relative
  width: 42rem

  .actions
    flex-direction: column
    align-items: center

  .title
    border-bottom: 1px solid $gray-2
    margin-bottom: 2rem

  .close
    cursor: pointer
    position: absolute
    top: 0.125rem
    right: 0

    svg path
      transition: fill 200ms

    &:hover
      svg path
        fill: $black

  .light-text
    color: $gray-4
