@import ../../constants
@import ../../constants

.RichText
  width: 100%

  .ql-editor
    height: 20rem

  .ql-toolbar
    background-color: $gray-1
    +inner-shadow-2
    border: 0

    .ql-picker-label:hover, .ql-picker-label.ql-active
      color: $primary
      .ql-stroke
        stroke: $primary

    .ql-picker-item:hover, .ql-picker-item.ql-selected
      color: $primary
      .ql-stroke
        stroke: $primary

    button:hover
      .ql-stroke
        stroke: $primary
      .ql-fill
        fill: $primary

    button.ql-active
      .ql-stroke
        stroke: $primary
      .ql-fill
        fill: $primary

    .ql-picker
      color: $black

    .ql-stroke
      stroke: $black

    .ql-header
      margin-right: 0.75rem

      .ql-picker-label
        border: 1px solid $gray-2
        background-color: $white
        border-radius: 16px

        .ql-stroke
          stroke: $primary

    .ql-size
      margin-right: 0.5rem

      .ql-picker-label
        border: 1px solid $gray-2
        background-color: $white
        border-radius: 16px

        .ql-stroke
          stroke: $primary

    .ql-picker-options
      border-radius: 16px

    .ql-fill
      fill: $black

  .ql-container
    background-color: $gray-1
    +inner-shadow-2
    border: 0

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

.ql-snow.ql-toolbar
  button
    svg
      width: 20px
      height: 20px

  button.ql-active
    svg
      path
        fill: $primary

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before
  content: attr(data-value)
