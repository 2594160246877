@import '../../components/constants'

.desk-pointer
  width: 2.625rem
  height: 2.625rem
  border-radius: 50%
  background-color: #67AC7C
  border: 3px solid $white
  color: $white
  font-size: 20px
  font-weight: 800
  text-align: center
  line-height: calc(2.625rem - 6px)
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, .25)
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%) scale(var(--seat-scale))
  overflow: hidden
  z-index: 2

  &.reserved
    background-color: #F06161
  &.dim
    z-index: 1
    width: 2rem
    height: 2rem
    background-color: #B0C8B7
  &.reserved.dim
    background-color: #DCA8A8
