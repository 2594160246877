@import ../../../components/constants

$map-width: 38rem

.ManageFloorPlan
  max-width: 1400px

  .Heading
    margin-top: 0.5rem
    align-items: center

    .DayNav
      margin-left: 0

    .date-range
      font-weight: 500

  .occupancy
    margin-top: 0.2rem
    margin-bottom: 0.5rem
    font-weight: 400
    font-size: 14px
    color: $gray-4
    display: flex
    align-items: center

    & .infoIcon
      width: 25px
      height: 25px
      margin-right: 8px
      fill: $gray-4

  .main-floorplan-content
    width: 100%
    display: flex
    flex-direction: row
    align-items: flex-start
    height: calc(var(--view-height-safe) - 19.5rem)

    &.capacity-limit
      height: calc(var(--view-height-safe) - 21.5rem)

    .floor-plan
      flex-grow: 1
      padding: 0
      height: 100%
      display: flex
      justify-content: stretch
      align-items: stretch

      > *
        width: 100%

    .user-list
      width: 20rem
      margin-left: 2rem
      display: flex
      flex-direction: column
      height: 100%
      padding: 0

  .missing
    min-width: 36rem
    height: calc(var(--view-height-safe) - 19.5rem)
    width: 100%
    display: flex
    justify-content: center
    align-items: center

    .error
      font-size: 14px
      line-height: 24px
      text-align: center
      padding: 2rem

  .UserSelector
    margin-left: auto
