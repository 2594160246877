@import ../../components/constants

.VisitorView
    display: flex
    flex-direction: column
    align-items: center
    flex-grow: 1
    background-color: $white

    &.scanner-active
        background: transparent

    .info
        max-width: 26rem
        text-align: center
        margin-bottom: 1rem

        @media (max-width: 480px)
            max-width: 21rem

    .qr_code
        margin-top: 2rem
        text-align: center

        img
            height: 10rem
            width: 10rem

    .Button
        width: 21rem
        justify-content: center

    .IconButton
        background-color: $gray-1
        padding: 12px 24px
        width: 21rem
        justify-content: center
