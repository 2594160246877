.TopNav
  display: flex
  flex-direction: row
  align-items: center
  height: 3.8rem
  padding: 1rem 1rem 1rem 1rem

  &.top
    height: 3.8rem
    padding: 1rem

  .back-arrow
    width: 24px
    text-align: left

    .arrow
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      display: flex
      justify-content: center
      align-items: center

      svg path
        fill: black

  .title
    flex-grow: 1
    text-align: left
    font-weight: bold
    padding-left: 2rem

    &.center
      text-align: center
      padding-left: 0

  .right-icon
    width: 24px
    text-align: right
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    display: flex
    justify-content: center
    align-items: center

    svg
      width: 24px
      min-height: 24px

      path
        fill: black
