@import ./../../constants

.Clock
  +flex-center

  font-style: normal
  font-weight: normal
  font-size: 14px

  display: flex
  align-items: center
  font-feature-settings: 'pnum' on, 'onum' on

  div
    margin:
      left: 1rem
      right: 1rem
