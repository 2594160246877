@import constants

.ModalForm.access-activity
  width: 36rem

  .title
    margin-bottom: 0

  .description
    font-weight: 400
    font-size: 14px
    line-height: 18px
    margin-top: 1rem
    padding-bottom: 1rem
    border-bottom: 1px solid $gray-2
    margin-bottom: 1rem

  .access-title
    font-weight: 700
    font-size: 14px
    line-height: 18px
    margin-bottom: .5rem

  .loader-wrapper
    height: 5rem
    display: flex
    justify-content: center
    align-items: center

  .no-activity
    height: 5rem
    width: 100%
    line-height: 5rem
    text-align: center
