
@import ../../components/constants

.ManageNav
    .asset-more
        display: flex
        justify-content: center
        align-items: center

        span
            margin-right: 6px

        svg
            path
                fill: $gray-4