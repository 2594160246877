@import ../../../components/constants

.Desks
  display: flex
  flex-direction: column

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: $white
