\:root
  --background-mobile: #f7f7f7
  --background-mobile-secondary: #EAEAEA

  --view-height-safe: calc(var(--vh, 1vh) * 100)

  --view-padding-top-safe: 0
  --view-padding-top-safe: const(safe-area-inset-top, 0)
  --view-padding-top-safe: env(safe-area-inset-top, 0)

  --view-padding-bottom-safe: 0
  --view-padding-bottom-safe: const(safe-area-inset-bottom, 0)
  --view-padding-bottom-safe: env(safe-area-inset-bottom, 0)
