@import ../../components/constants

.VisitorForm
    display: flex
    flex-direction: column
    align-items: center
    flex-grow: 1

    .fields
        display: flex
        flex-direction: column
        gap: 2rem
        margin-top: 2rem
        margin-bottom: 2rem
        padding: 0 1rem

        .Input
            width: 21rem

        .AsyncSelect
            width: 21rem

        .Button
            width: 100%
