@import '../../../components/constants'

.VisitorEditForm
    width: 46rem

    .additional-info-input
        textarea
            min-height: 8rem

.VisitFormAlert
    width: 46rem
