.Invoices
  .Intro
    margin-top: 1rem
    margin-bottom: 2rem

  .Loader
    margin-top: 10rem
    align-self: center

.InvoicesEmptyTableRows
  padding: 2rem
  text-align: center
  font-size: .875rem
