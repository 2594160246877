.Payments
  margin-top: 2rem
  .section-content
    display: grid
    grid-template-columns: 1fr auto
    column-gap: 1rem
    row-gap: 1rem
    align-items: start

    .PrepaidCodeCard
      width: 25rem
