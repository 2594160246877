@import '../../../../components/constants'
@import '../../../../utils'

.RoomSettingsForm
  .PageHeader
    .SwitchLabel
      color: $black
      font-size: toRem(16)


