@import ../../constants

.InputPhone
  width: 100%
  position: relative

  &.error svg path
    fill: $danger
  svg
    position: absolute
    right: 0.75rem
    top: 50%
    transform: translateY(-50%)

  input
    width: 100%
    border: 0
    resize: none

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 32px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black

  input[type=text]
    cursor: text

  input:focus, textarea:focus
    outline: none

  & .react-international-phone-input-container .react-international-phone-input
    height: inherit
    border: 0
    border-radius: inherit
    font-size: inherit

  & .react-international-phone-country-selector-button
    height: 100%
    background-color: $gray-6
    padding-left: 10px
    border: 0
    border-radius: 0

  & .react-international-phone-country-selector-dropdown
    outline: none

  &.desktop
    &.error
      +error-border

    input, textarea
      background: $gray-1
      +inner-shadow-2
      padding: 8px 12px
      transition: background-color 100ms

      &:hover, &:focus
        background-color: mix($gray-1, $gray-2, 66%)

  &.disabled
    input[disabled]
      color: $gray-4

  &.mobile
    input, textarea
      border-bottom: 2px solid $gray-2
      background: var(--background-mobile)
      -webkit-appearance: none
      font-size: $font-size-normal
      transition: border-bottom 300ms

      &:hover, &:focus
        border-bottom: 2px solid mix($gray-3, $gray-2, 66%)
    &.error
      input, textarea
        border-bottom: 2px solid $danger
