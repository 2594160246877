@import ../../../components/constants

.Help
  display: flex
  flex-direction: column
  background: var(--background-mobile)
  text-align: center

  .body
    flex-grow: 1
    padding: 0 1rem

    .logo
      margin: 0 0 3rem 0

      img
        width: 8rem
        height: 8rem

    h2
      font-size: $font-size-title
      margin: 0 0 0.5rem 0

    p
      font-size: $font-size-normal
      font-weight: 100
      margin: 0 0 1rem 0
