@import ../../../components/constants

.Login
  display: flex
  flex-direction: column
  background: var(--background-mobile)
  text-align: center

  .Logout
    margin: 0
    padding: 1rem

  .body
    flex-grow: 1
    padding-left: 1.5rem
    padding-right: 1.5rem
    padding-top: var(--view-padding-top-safe)

    .logo
      margin: 3.8rem 0 2rem 0
      @media screen and (min-height: 700px)
        margin-bottom: 3rem

      img
        width: 8rem
        height: 8rem

    h2
      font-size: $font-size-title
      margin: 0 0 0.5rem 0

    p
      font-size: $font-size-normal
      font-weight: 100
      margin-bottom: 1rem

      & ~ p
        margin-bottom: 2rem

    .Input
      margin-bottom: 0.5rem

    .input-error
      color: $danger
      text-align: left
      font-weight: bold
      font-size: $font-size-small

    .input-info
      text-align: left
      font-weight: bold
      font-size: $font-size-small

  .action
    padding: 1rem 1.5rem calc(1rem + var(--view-padding-bottom-safe)) 1.5rem

  .back-button
    position: absolute
    top: 1rem
    left: 1rem
    width: 3rem
    height: 3rem
    display: flex
    align-items: center
    justify-content: flex-start

    svg
      width: 2rem
      height: 2rem

      path
        fill: $black

  .resend-code-button
    color: $black
    width: 100%
    margin-top: 1.5rem
    font-size: $font-size-normal
