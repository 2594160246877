@import './constants'

.you-are-here
  height: 4.2rem
  position: absolute
  text-decoration: none
  cursor: default
  overflow: hidden
  background-color: transparent
  transform-origin: 50% 95%
  transform: translate(-50%, -100%) scale(var(--seat-scale))
  pointer-events: none

  svg path
    fill: $primary

  .text-wrapper
    position: absolute
    top: 14px
    left: 0
    height: 2.2rem
    width: 3rem
    display: flex
    flex-direction: column
    align-items: center
    color: $white
    text-align: center
    line-height: 1.1
    overflow: hidden

    .you
      font-size: 9px

    .here
      font-size: 12px
      font-weight: 700

  &.dark
    .text-wrapper
      color: $primary
    svg path
      fill: $white
