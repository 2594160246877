@import constants

.Footer
  +shadow-1
  +flex-center
  background: $white
  color: $gray-3

  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 64px

  font-feature-settings: 'pnum' on, 'onum' on

  color: $gray-3

  .row
    height: 4rem
    width: 100%
    +flex-center
  
  &.isCentered
    .row
      width: unset
      
  &:not(.isConstrained) .row
    padding:
      left: 1rem
      right: 1rem


  &.isConstrained .row
    max-width: 73rem

  .copy
    width: 13rem
    padding-left: 1.5rem
  
  .menu
    display: flex
    align-items: center
    justify-content: flex-start
    flex-grow: 1

    a
      text-decoration: none
      color: $gray-3
      margin: 0 1rem

      &:hover
        color: $gray-4