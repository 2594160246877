@import ../../../components/constants

.Summary
  display: flex
  flex-direction: column

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .summary-title
      font-size: $font-size-title
      font-weight: bold
      padding: 0 1.5rem
      margin-bottom: 1.5rem

    .details
      flex-grow: 1
      position: relative

      .scroller
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        overflow: scroll

        .detail-row
          cursor: pointer
          -webkit-user-select: none
          -moz-user-select: none
          -ms-user-select: none
          user-select: none
          display: flex
          border-bottom: 1px solid $gray-3
          padding: 0.8rem 1rem

          .data
            flex-grow: 1

            .type
              color: $gray-5
              font-size: $font-size-small

          .edit
            color: black
            display: flex
            align-items: center

  .action
    padding: 1rem 1rem calc(1rem + var(--view-padding-bottom-safe)) 1rem

  .failed-meetings
    position: absolute
    bottom: 0
    left: 0

    width: 100%
    background-color: white

    padding: 1rem

    .container
      max-height: 15rem
      overflow-y: auto
      margin: 1rem
      background-color: $gray-1

    .row
      padding: .5rem
      font-size: $font-size-small
