@import ../../constants

.SignatureInput

  .Label
    width: 100%
    display: flex
    justify-content: space-between
    color: $gray-4
    font-weight: bold

    svg
      cursor: pointer

  canvas
    background-color: $gray-2
