@import constants

.Card.old-card
  position: relative
  background: $white
  color: $black

  border-radius: 3px
  padding: 1rem

  +shadow-1

  &.small-font
    font-size: 0.9rem

  &.card-error::before
    content: " "
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 3px
    background-color: red
    transform: translate(0, 0)
