@import ../../constants

.ColorPicker
  width: 100%
  position: relative
  display: flex

  .HiddenColorPickerInput
    visibility: hidden

  &.error
    +error-border

  .color-display
    width: 48px
    height: 48px
    background-color: $gray-3
    position: relative

  .reset-color
    position: absolute
    diplay: flex
    justify-content: center
    cursor: pointer
    align-items: center
    width: 24px
    height: 24px
    z-index: 10
    top: 12px
    right: 4px

    svg
      path
        fill: $primary

  input
    width: 112px
    background: $gray-1
    +inner-shadow-2
    border: 0
    resize: none

    padding: 8px 12px

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

      svg
        path
          fill: $black

  input[type=text], input[type=email]
    cursor: text

  input:focus, textarea:focus
    outline: none

.disabled
  input[disabled], textarea[disabled]
    color: $gray-4

  svg.lock path
    fill: $gray-4

.disabled:hover
  svg
    circle
      fill: mix($gray-1, $gray-2)

  svg.lock path
    fill: $black
