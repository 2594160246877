@import ../constants

.RoomPicker
  background: var(--background-mobile)

  .picker-content
    padding: 0 1rem

    h2
      font-size: $font-size-title

    .room-item
      display: flex
      align-items: flex-start
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 100%
      background: white
      padding: 1rem
      margin-bottom: 1rem

      &.disabled
        cursor: auto
        opacity: 0.5

        .occupancy
          color: $danger

          span
            padding: 0 0.5rem
            background: $red-text-background

      .room-info
        flex-grow: 1

        .name
          font-size: large

        .amenities
          color: $gray-4

        .occupied-until
          color: $black
          margin-top: 0.5rem

      .occupancy
        display: flex
        align-items: center
        color: $primary
        margin-left: 1rem
        padding: 0 0.5rem

        span
          white-space: nowrap

          svg
            width: 1rem
            height: 1rem
            margin-left: 0.25rem

          path
            fill: $primary
