.CompanyDetailsForm
	display: grid
	column-gap: 2rem
	width: 100%
	grid-template-columns: 1fr 1fr

	.FieldWrapper
		width: 100%

	.vat-not-applicable
		margin-top: 3rem
