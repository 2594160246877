@import ../../components/constants
@import ../../utils

.RoomDeviceStatus
    display: flex
    gap: toRem(12)

    .status
        display: flex

        .icon
            width: toRem(24)
            height: toRem(24)
            &.warning
                svg
                    path
                        fill: $danger

        .label
            font-weight: 400
            font-size: toRem(12)
            line-height: toRem(24)
            color: $black
