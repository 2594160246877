@import constants

.Breadcrumbs
    & a
        color: $black
        &:visited
            color: $black
        &:hover
            color: $primary
            text-decoration: none
