@import ../constants

.ReservationDeleteMenu
  background: white

  .picker-content
    background: white
    padding-bottom: var(--view-padding-bottom-safe)

    .section-head
      border-bottom: 1px solid $gray-2

    .section
      padding: 0 1rem
      border-bottom: 1px solid $gray-2

      .option
        display: flex
        align-items: center
        padding: 1rem 0

        .option-icon
          display: flex
          align-items: center
          width: 3rem

          svg path
            fill: black

        .option-name
          cursor: pointer
          -webkit-user-select: none
          -moz-user-select: none
          -ms-user-select: none
          user-select: none
          flex-grow: 1
          font-weight: bold
