@import '../components/constants.sass'

.Pagination
  display: flex
  justify-content: center
  align-items: center
  padding: 0 1rem

  div.summary
    margin-right: 1rem
    transform: translateY(-1px)
    min-width: 14rem

  div.links
    margin-right: 0
    display: flex

  button
    font-weight: bold
    cursor: pointer
    color: $primary
    background: $white
    min-width: 2rem
    height: 1.5rem
    line-height: 1.5rem
    text-align: center
    border: none
    margin: 0 0.25rem
    border-radius: 2rem
    display: flex
    align-items: center
    justify-content: center

    span
      display: block
      transform: translateY(-1px)

  button:hover
    background: $primary
    color: $white

  button:disabled
    cursor: default
    opacity: 1
    color: $gray-3

    &:hover
      cursor: default
      color: $gray-3
      background: $white

  button.current
    cursor: default
    background: $white
    color: $gray-3
    border: 1px solid $gray-3

    &:hover
      cursor: default
      color: $gray-3
