@import ./../../constants

.Avatar
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  svg
    --user-color: #{$gray-2}
    width: 1rem
    height: 1rem

    circle
      fill: var(--user-color)

  img
    object-fit: cover
    object-position: center

  &.small, &.small img
    width: .875rem
    height: .875rem

  &.medium, &.medium img
    width: 1.5rem
    height: 1.5rem
    font-size: 1.5rem

  &.large, &.large img
    width: 11rem
    height: 11rem
    font-size: 10rem

  &.circle
    border-radius: 50%

svg.Avatar
  --user-color: #{$gray-2}
  width: 1rem
  height: 1rem

  circle
    fill: var(--user-color)
