@import ../../../../components/constants
@import ../../../../utils

.RoomDeviceDetail
  .loading
    align-self: center

  .device-detail-content
    width: 100%
    display: flex

  .info-panel
    margin-left: toRem(32)

    .infos, .settings
      padding-bottom: toRem(32)
      margin-bottom: toRem(32)
      border-bottom: 1px solid $gray-2

    .infos
      display: flex
      align-items: flex-start
      align-content: flex-start
      flex-wrap: wrap
      gap: toRem(24)
      width: toRem(315)
      font-size: toRem(14)

      .info
        display: inline-block
        width: calc(100% / 2 - 16px)

        .info-name
          color: $gray-4
          font-weight: 700
          margin-bottom: toRem(12)

        .info-data
          line-height: toRem(24)

      .info:first-child
        width: 100%

    .settings
      &-item
        display: flex
        justify-content: space-between

        &:not(:last-child)
          margin-bottom: toRem(12)

        > div, > a
          width: calc(100% / 2 - 16px)

        &-label
          color: $gray-4
          font-size: toRem(14)
          font-weight: 700
          line-height: toRem(24)

        &-value
          display: inline-flex
          align-items: center
          justify-content: flex-end
          color: $primary
          font-size: toRem(12)

      .settings-actions
        margin-top: toRem(24)

        > div:not(:last-child)
          margin-bottom: toRem(12)

        .settings-action
          font-size: toRem(14)
          line-height: toRem(16)
          font-weight: 700

    .actions
      display: flex
      gap: toRem(16)
