@import ../constants

.PersonForm
  width: 45rem

  &.AlignRight
    .actions
      justify-content: flex-end

  &.isInvalid
    input:invalid
      background: mix($gray-1, $danger, 90%) !important
