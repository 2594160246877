@import ../../../components/constants

.Suggest
  display: flex
  flex-direction: column
  background: var(--background-mobile)

  .scrollable
    overflow-y: auto

  .body
    padding: 0 1rem
    padding-bottom: 1rem
    display: flex
    flex-direction: column
    flex-grow: 1

    .label-custom
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-bottom: 0.75rem
      font-size: 10pt
      text-transform: uppercase
      letter-spacing: 0.8px
      padding: 0 0.5rem
      margin-top: 1rem

    .wrapper
      flex-grow: 1

    .title
      font-size: $font-size-title
      font-weight: bold
      margin-bottom: 1.5rem

    .next-button
      width: 100%

    .question
      margin-top: 1rem

    .padding-bottom
      padding-bottom: 1rem
