@import ../../../components/constants

.About
  display: flex
  flex-direction: column

  .body
    flex-grow: 1
    background: var(--background-mobile)
    display: flex
    flex-direction: column

    .logo-box
      display: flex
      justify-content: center
      align-items: flex-start
      height: 6rem

    .logo
      padding: 0.5rem
      background-color: white
      border-radius: 1rem
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1)
      display: flex
      justify-content: center
      align-items: center
      width: 5rem
      height: 5rem

      img
        height: 100%
        width: 100%

    .version
      color: $gray-4
      font-size: small
      text-align: center
      margin-bottom: 2rem

    .description
      padding: 0 1rem
      text-align: center
      flex-grow: 1

      p
        font-size: $font-size-normal
        margin: 0 0 1rem 0

      a
        color: black
        text-decoration: underline

    .app-switch
      display: flex
      justify-content: center
      margin: 3rem 0
      gap: 0.25rem

      .switch
        color: $primary
        cursor: pointer
