@import ../../components/constants

.VisitorFooter
    margin-bottom: 7rem
    display: flex
    flex-direction: column
    gap: 2rem

    @media (max-width: 480px)
        margin-bottom: 3rem

    .notice
        text-align: center
        color: $gray-3
        width: 21rem
