@import constants

.Heading
  display: flex
  justify-content: center
  align-items: flex-start

  font-style: normal
  font-weight: 700
  font-size: 18px
  line-height: 25px

  font-feature-settings: 'pnum' on, 'onum' on

  color: $black

  .space
    flex-grow: 1

  .spacer
    margin: 0 12px

  &.isCentered
    width: 100%
    align-items: center
