@import '../../components/constants'

.desk-list-item
  padding: .5rem .5rem .5rem 0
  border-bottom: 1px solid $gray-3
  color: $gray-4
  font-weight: 700
  font-size: 10px

  @media (min-width: 768px)
    font-size: 15px

  .desk-info
    display: flex
    align-items: center

    .desk-list-icon
      flex: 0 0 auto
      width: 2rem
      height: 2rem
      border-radius: 50%
      background-color: #67AC7C
      border: 3px solid $white
      color: $white
      font-weight: 800
      text-align: center
      line-height: calc(2rem - 6px)
      box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, .25)
      margin-right: 1rem
      overflow: hidden

      &.reserved
        background-color: #F06161

    .desk-name
      overflow: hidden
      text-overflow: ellipsis

  &.reservations
    align-items: flex-start

    .desk-list-icon
      margin-top: .5rem

  .free
    flex: 1 0 auto
    text-align: end
    font-weight: 600

  .reservation-details
    width: 100%
    overflow: hidden

    .reservation-item
      display: flex
      flex-direction: row
      justify-content: space-between
      margin-left: 3rem

      .name
        flex: 1 1 auto
        font-weight: 400
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

      .time-slot
        flex: 1 0 auto
        font-weight: 600
        flex-shrink: 0
        text-align: right

  .amenities
    display: flex
    flex-wrap: wrap
    max-width: 100%
    white-space: nowrap
    overflow: hidden
    padding:
      left: 3rem
      top: .25rem

    & + .reservation-details
      padding-top: .5rem

    .amenity
      background-color: #f2f2f2
      padding: 0 0.25rem
      margin-bottom: 0.25rem
      margin-right: .5rem
      text-overflow: ellipsis
      overflow: hidden
