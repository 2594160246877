@import ../constants

.Field
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 1.5rem

.FieldWrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 100%
  margin-bottom: 1.5rem

  .Field
    margin-bottom: 0

  .RequiredMarker
    margin-left: 0.25rem
    color: $danger

  .HelpText
    color: $gray-4
    font-size: 0.75rem
    margin-top: .5rem
