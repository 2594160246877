@import "../../../components/constants"

.EmailSettingsForm

	.Info-subtext
		color: $gray-4
		display: flex
		align-items: center
		margin-top: 2em
		margin-bottom: 1em
		font-size: 14px
		line-height: 24px
		align-items: flex-start

		& svg
			margin-right: 2em
			fill: $gray-4
			width: 44px
			height: 44px

	svg
		margin-right: 1rem
		fill: $gray-4
		width: 20px
		height: 20px
