@import ./../../constants

.LanguageSelector
    position: absolute
    right: 32px
    top: 32px
    width: 100px

    .options
        background: $white
        padding: 12px
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)
        text-transform: uppercase
        display: flex
        flex-direction: column
        gap: 8px

        .option
            cursor: pointer

            span
                display: flex
                align-items: center
                justify-items: center
                gap: 8px

                svg
                    path
                        fill: $primary

    .control .label
        font-size: 16px
        font-weight: 500
        text-align: center
        padding: 12px
        text-transform: uppercase
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        gap: 8px

        svg
            path
                fill: $primary
