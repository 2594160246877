@import '../../components/constants'

.licenses-info-list
	margin-top: 1.5rem
	margin-bottom: 0.75rem

	.license-info
		display: flex
		align-items: center

		.license-info-icon
			width: 1.25rem
			height: 1.25rem
			margin-right: .5rem

			svg
				width: 1.25rem
				height: 1.25rem
