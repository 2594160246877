@import ../constants

.Label
  font-style: normal
  font-weight: bold
  font-size: 14px
  line-height: 19px

  color: $gray-4

  margin-bottom: 1rem

  .subText
    margin-left: 0.5rem
    font-weight: 400
