@import ../constants

.FloorPicker
  background: var(--background-mobile)
  display: flex
  flex-direction: column

  &__body
    padding: 0 1rem 1rem
    display: flex
    flex-direction: column
    flex-grow: 1

    .wrapper
      flex-grow: 1

    .scrollable
      overflow-y: auto

    h2
      font-size: $font-size-title

    .floor-item
      display: flex
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 100%
      background: white
      padding: 1rem
      margin-bottom: 1rem

      &.disabled
        cursor: auto
        opacity: 0.5

        .occupancy
          color: $danger

          span
            padding: 0 0.5rem
            background: $red-text-background

            path
              fill: $danger

      .name
        flex-grow: 1
        font-size: large

      .occupancy
        display: flex
        align-items: center
        justify-content: right
        color: $primary
        padding: 0 0 0 0.5rem

        span
          white-space: nowrap

          svg
            width: 1rem
            height: 1rem
            margin-left: 0.25rem
            transform: translateY(2px)

          path
            fill: $primary

      .occupancy-loading svg
        height: 1.2rem
        width: 1.2rem
