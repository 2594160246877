.NotFoundNotice
  width: calc(100% - 12rem) !important
  height: calc(var(--view-height-safe) - 16rem) !important
  min-height: 100% !important
  min-width: 32rem !important
  display: flex !important
  flex-direction: column !important
  justify-content: center !important
  align-items: center !important
  text-align: center !important

  code
    display: inline-block
    background-color: #fff
    margin-top: 1rem
    padding: 0.33rem 0.5rem