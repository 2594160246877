@keyframes expandY
	0%
		transform: scaleY(0)

	100%
		transform: scaleY(1)

.alert
	font-size: 13px
	padding: 1.7rem
	animation: 0.2s expandY

	&.warning
		background-color: rgb(255, 253, 226)

	&.info
		background-color: rgb(112, 134, 255)

	&.error
		background-color: rgb(255, 131, 131)
