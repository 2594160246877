@import ../constants

.asset-reservation-row
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  display: flex
  background: white
  border-left: 4px solid white
  padding: 0.5rem
  margin-bottom: 0.5rem

  &.canBeActive
    border-left: 4px solid $primary

    .actions-row .action
      color: $primary

  &.isActive
    border-left: 4px solid $danger
    background: $black
    color: $white

    .actions-row .action
      color: $white

    .resource-info
      .aux-info
        color: $gray-1

    .resource-icon
      svg path
      fill: $white

  .resource-info
    flex-grow: 1

    .desk-name
      font-weight: bold
      margin-bottom: 0.25rem

    .aux-info, .checkout-info, .diff-tz, .building-floor
      font-size: $font-size-small
      color: $gray-4
      text-overflow: ellipsis
      overflow: hidden
      width: 16rem
      white-space: nowrap

    .checkout-info
      margin:
        top: 0.5rem
        bottom: 0.5rem

    .actions-row
      padding: 0.75rem 0 0.5rem 0

      .action
        font-weight: bold
        text-transform: uppercase
        cursor: pointer
        margin-right: 1.5rem

  .resource-icon
    width: 2rem
    display: flex
    justify-content: center
    align-items: center

    svg path
    fill: $gray-9
