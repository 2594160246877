@import ../constants

.asset-form
  width: 28rem

  .DepartmentsMultiField, .asset-enabled-field, .description
    width: 100%

    p
      color: $gray-3
