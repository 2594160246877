@import ../components/constants

.CorrectIconMapper
  width: 2rem
  display: flex
  justify-content: center
  align-items: center

  svg
    path
      fill: $gray-9

  svg.white
    path
      fill: $white
