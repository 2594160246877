@import ../../components/constants

@keyframes gradient-wipe
  0%
    background-position: 0vw 0
  100%
    background-position: 200vw 0

.Home
  .LaunchButton
    position: relative

  .LaunchButton:after
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 3px
    background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
    bottom: -4px
    z-index: 2
    // opacity: 0
    transition: opacity 200ms
    pointer-events: none

  &.isLoading
    .LaunchButton:after
      animation: gradient-wipe 1600ms linear infinite
      opacity: 1
      transition: opacity 800ms
