@import ../../../components/constants

.BuildingSelect
  display: flex
  flex-direction: column

  .head
    width: 100%
    background: var(--background-mobile)

    .icon
      display: flex
      justify-content: center
      align-items: flex-start
      height: 8rem

      div
        padding: 0.5rem

        svg
          width: 5rem
          height: 5rem

        svg path
          fill: black

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)
    margin: 2rem 1rem 1rem 1rem

    .main-data
      display: flex
      flex-direction: column
      flex-grow: 1

    .title
      font-size: 2rem
      font-weight: 700
      text-align: center
      margin-bottom: 2rem

    .intro
      font-size: 1rem
      font-weight: 400
      line-height: 24px
      text-align: center
      margin-bottom: 2rem

  .BuildingSelectForm
    display: flex
    flex-direction: column
    flex-grow: 1

    .PageForm
      display: flex
      flex-direction: column
      flex-grow: 1
      justify-content: space-around

    .Card
      min-width: unset
      background: var(--background-mobile)
      box-shadow: none

      .fields
        padding: 1rem

      .AsyncSelect .async-select-input__control
        background: $white

    .PageActions
      .spacer
        display: none

      .Button.submit
        width: 100%
