@import 'constants'

.OccupancyInfo
	font-weight: 400
	font-size: 14px
	color: $gray-4
	display: flex
	align-items: center

	& .InfoIcon
		width: 25px
		height: 25px
		margin-right: 8px
		fill: $gray-4
