@import "../../../components/constants"
@import "../../../utils"

.VisitorSettingsForm
	width: 100%
	padding: 0

	.Subtext
		color: $gray-4
		font-size: 12px
		font-weight: 400
	.mw-50
		max-width: 50%

	.add-document
		width: 100%
		margin-bottom: 1.5rem
		margin-top: -.6rem
		margin-left: -3px
		display: flex
		.Button
			font-size: 14px
			font-weight: 500
			.label
				display: flex
				align-items: center
		svg
			margin-right: .5rem

	.fields
		.StoreIcons
			svg
				margin-right: 1rem

	.visitor-settings-header
		&__controls
			display: flex
			align-items: center
			gap: toRem(16)

		&__content
			margin-top: toRem(16)

	.SettingsGroup
		padding-left: calc(40px + 1rem)
		width: 100%
		margin-bottom: 2.5em
		& .Row
			display: flex

	& button.EditButton
		padding: 10px 16px
		letter-spacing: 1.5px
		font-size: 14px

	& .m-0
		margin: 0

	& .licenses-info-list
		margin-top: 0

		& .license-info
			svg
				margin-right: toRem(12)

			& .license-info-details
				margin-left: 0

	& .subtext
		margin-top: toRem(16)

	& .building-missing
		text-align: center
		min-height: auto
		height: auto
		color: $gray-4
		padding: toRem(48) toRem(64)
		background: #FEFFDE