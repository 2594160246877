@import ../components/constants

.access-activity-button
  cursor: pointer
  background-color: transparent
  border: none
  color: $gray-4

  &:hover
    color: $black
