
@import ./../../constants

.GooglePlacesSelect
  width: 100%

  .Wrapper
    position: relative
    width: 100%

  .PlacesList
    position: absolute
    background-color: $white
    width: 100%
    z-index: 1
    border-radius: 3px
    margin-top: 0.5rem
    +shadow-1

    .loading
      display: flex
      justify-content: center

      .Loader
        margin-top: 0.2rem
        margin-bottom: 0.2rem

    .PlaceItem
      padding: 0.6rem 1rem
      cursor: pointer
      color: $gray-4

      &:hover
        color: $primary
