@import 'constants'

.Intro
  font-weight: 400
  font-size: 16px
  color: $gray-4
  width: 100%

  &.isConstrained
    max-width: 42rem

  &.isCentered
    width: 100%
    text-align: center

  p
    margin-top: 0.5rem

  p.notice
    border: 1px solid mix($gray-2, $gray-3)
    padding: 0.25rem 0.5rem 0.35rem
    font-size: 14px
