@import '../../components/constants'

.SettingsBuildings

  .Filters
    width: 42rem

  .Card
    width: 42rem
    margin-bottom: 3rem

  .building
    margin: 1rem

    .main
      display: flex
      align-items: center
      margin-bottom: 1.5rem

      .data
        flex-grow: 1
        display: flex
        flex-direction: column
        align-items: stretch
        justify-content: flex-start

        .primary
          font-weight: 700
          font-size: 16px

        .secondary
          font-weight: 400
          font-size: 16px
          color: $gray-4

    .map
      height: 300px
      background: $gray-1

    .statistic
      display: flex

      .info
        margin: 2rem 0
        width: 25%

        .number
          text-align: center
          font-size: $font-size-title
          font-weight: 700
          color: $gray-4

        .name
          text-align: center
          font-size: $font-size-small
          font-weight: 400
          color: $gray-4

.LocationPin
  position: absolute
  left: -20px
  top: -40px

  svg
    height: 40px
    width: 40px
