@import ../../../../components/constants
.PaymentPlanCard
  min-width: 20rem
  max-width: 30rem
  displat: flex
  flex-direction: column
  flex-basis: 100%
  flex-grow: 1

  &__title, &__price
    font-size: 2rem
    line-height: 2.75rem
    font-weight: 700

  &__current
    height: 1.5rem
    color: $gray-3
    font-weight: 700

  &__recommended
    color: $primary
    font-weight: 700

  &__description
    margin-top: 1.5rem
    color: $gray-4
    font-weight: 700

  &__features
    color: $gray-4
    padding: 0
    margin-left: .8rem
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr))
    column-gap: 1rem
  li
    margin-left: .5rem

  &__period
    font-weight: 700
    margin-bottom: 2rem

  &__price-wrapper
    justify-self: flex-end

  .Card

    margin-top: .5rem
    height: 100%

    .card-content-body
      display: flex
      flex-direction: column
      justify-content: space-between

  &.is-selected .Card
    margin-top: 0
    box-shadow: 0px 2.7px 2.2px 0px rgba(0, 0, 0, 0.02), 0px 6.6px 5.3px 0px rgba(0, 0, 0, 0.03), 0px 12.5px 10px 0px rgba(0, 0, 0, 0.04), 0px 22.3px 17.9px 0px rgba(0, 0, 0, 0.04), 0px 41.7px 33.4px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07)
