@import ./../../constants

.Button
  position: relative
  appearance: none
  border: none

  padding: 1rem 1.5rem
  cursor: pointer

  font-family: 'Noto Sans', sans-serif
  font-style: normal
  font-weight: 700
  font-size: 0.875rem
  line-height: 1rem

  display: flex
  align-items: center
  text-align: center
  justify-content: center
  letter-spacing: 0.07em
  text-transform: uppercase

  background: $primary
  color: $white

  transition: background-color 200ms, color 200ms

  &, &:link, &:active, &:visited, &:hover
    text-decoration: none
    color: $white

  &:hover
    background: $primary-darker
    transition: background-color 100ms, color 100ms

  &.mobile-action
    width: 100%
    font-size: $font-size-normal
    border-radius: 2px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    &:active
      background: $primary-darker
      transition: background-color 100ms, color 100ms

  &.isSmall
    line-height: 1.125rem
    padding: .625rem 1rem

  &.danger
    color: $danger
    background: $white

    &:hover
      background: $danger-bkg

  &.danger-pop
    color: $danger
    background: $danger-bkg

    &:hover
      background: $danger-bkg-2

  &.secondary
    color: $primary
    background: $gray-1

    &:hover
      background: $gray-2

  &.secondary-white
    color: $primary
    background: $white

    &:hover
      background: $gray-2

  &:focus
    outline: none

  &.isDisabled
    filter: grayscale(100%)
    opacity: 0.3
    cursor: not-allowed

  &.link
    color: $primary

    padding: 0
    font-size: unset
    background-color: unset
    letter-spacing: unset
    text-transform: unset
    &:hover
      color: $primary-darker

  &.isLoading
    color: transparent

    .loader
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    &.secondary, &.secondary-white, &.link
      .loader, .button--icon--loader
        .laoder-primary
          fill: $primary-lighter
        .loader-secondary
          fill: $primary

    &.danger, &.danger-pop
      .loader, .button--icon--loader
        .laoder-primary
          fill: $danger-bkg
        .loader-secondary
          fill: $danger

  &.has-icon
    &:not(.link)
      padding: .75rem 1.5rem .75rem 1rem

    &.isSmall
      padding: .5rem 1rem

    .button-icon
      width: 1.5rem
      height: 1.5rem
      margin-right: .5rem

      &>svg>path
        fill: $white

    &.secondary, &.link, &.secondary-white
      .button-icon
        &>svg path
          fill: $primary

    &.link:hover
      .button-icon
        &>svg path
          fill: $primary-darker

    &.danger, &.danger-pop
      .button-icon
        &>svg path
          fill: $danger

    &.isLoading
      color: $white

      &.secondary
        color: $primary

      &.secondary-white
        color: $primary

      &.danger, &.danger-pop
        color: $danger

      &.link
        color: $primary
