@import ../constants

.BuildingPicker
  background: var(--background-mobile)
  overflow-y: scroll

  .picker-content
    padding: 0 1rem

    h2
      font-size: $font-size-title

    .building-item
      cursor: pointer
      display: flex
      align-items: flex-start
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 100%
      background: white
      padding: 1rem
      margin-bottom: 1rem

      .building-info
        flex-grow: 1

        .name
          font-size: large

        .address
          color: gray

      .occupancy
        display: flex
        align-items: center
        justify-content: right
        color: $primary
        padding: 0 0 0 0.5rem

        span
          white-space: nowrap

          svg
            width: 1rem
            height: 1rem
            margin-left: 0.25rem
            transform: translateY(2px)

          path
            fill: $primary

      &.disabled
        cursor: auto
        opacity: 0.5

        .occupancy
          color: $danger

          span
            padding: 0 0.5rem
            background: $red-text-background

            path
              fill: $danger

      .occupancy-loading svg
        height: 1.2rem
        width: 1.2rem
