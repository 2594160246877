@import ../constants

.StartEndField
  width: 100%

  input
    background: $gray-1
    border: 0

    padding: 8px 12px

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

  input:focus
    outline: none

  .dual-inputs
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center

    input
      width: calc(50% - 1rem)

  .HelperText
    color: $gray-3
    margin-bottom: 0
