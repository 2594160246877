@import '../../constants'

.AsyncSelect
  width: 100%

  &.error
    +error-border

  .async-select-input
    &__single-value
      outline: 1px solid transparent

    &__dropdown-indicator
      color: $primary

      &:hover
        color: $black

    &__groupHeading
      color: $black

    &__control
      background-color: $gray-1
      border-width: 0
      +inner-shadow-2
      cursor: pointer
      min-height: 3rem

      &:hover
        background-color: $gray-6

      &--is-focused
        background-color: $gray-6

    &__indicator-separator
      display: none

    &__multi-value__remove:hover, &__clear-indicator:hover
      color: $danger
      background-color: transparent

  &.disabled.async-select-input--is-disabled
    pointer-events: all

    .async-select-input
      &__placeholder, &__dropdown-indicator
        color: $gray-3

      &__control
        cursor: not-allowed

        &:hover
          background-color: $gray-1

body .async-select-input
  &__menu-portal
    z-index: 300

  &__menu
    +shadow-1
    border-radius: 3px

  &__option
    background-color: white
    cursor: pointer
    color: $gray-4
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

    &--is-focused
      color: $primary

    &:active
      background-color: $gray-2

    &--is-selected
      color: $black

      &:active
        background-color: $gray-2
