@import ../constants

.CsvInvite
  min-width: 46rem

  .Card
    padding: 2rem
    width: 100%

    .section
      margin-bottom: 2rem

      .info, .Switch
        margin-bottom: 1.5rem

      .Table
        width: 58rem

        .info-text
          width: 10rem
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden

        .status-error
          svg
            height: 1rem
            path
              fill: $danger

        .status-check
          svg
            height: 1rem
            path
              fill: $valid

.InvalidData
  color: $danger
