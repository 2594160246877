@import '../../constants'

@keyframes gradient-wipe
  0%
    background-position: 0vw 0
  100%
    background-position: 200vw 0

.Table
  +shadow-1
  position: relative
  background: $white
  border-radius: 3px
  width: 100%
  border-spacing: 0

  .no-data-cell
    color: $gray-3

  &:before
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 3px
    background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
    top: 48px
    left: 0
    z-index: 2
    opacity: 0
    transition: opacity 200ms
    pointer-events: none

  &.loading:before
    animation: gradient-wipe 1600ms linear infinite
    opacity: 1
    transition: opacity 800ms

  table
    width: 100%
    border-spacing: 0
    table-layout: fixed

  tr:first-child th:first-child
    border-top-left-radius: 3px

  tr:first-child th:last-child
    border-top-right-radius: 3px

  tr:last-child td:first-child
    border-bottom-left-radius: 3px

  tr:last-child td:last-child
    border-bottom-right-radius: 3px

  tr.clickable-row
    background-color: $white
    transition: background-color 200ms
    cursor: pointer

    &:hover
      background-color: $gray-1

  .checkbox-cell
    width: 3rem

  .number-cell
    width: 2rem
    text-align: center
    font-weight: bold
    color: $gray-3
    padding-left: 0
    text-align: right

  .clickable-cell
    cursor: pointer
    font-weight: bold

  .empty-cell
    padding: 0

    .empty-div
      font-size: 14px
      color: $gray-4
      padding: 1rem
      text-align: center

  td.action-cell
    vertical-align: bottom
    width: 4rem
    transition: background-color 200ms
    padding: 0
    vertical-align: middle
    height: 1px

    &:hover
      background-color: mix($gray-1,$gray-2)
      cursor: default

  thead
    font-style: normal
    font-weight: bold
    font-size: 14px
    color: $gray-3
    text-align: left

    th
      z-index: 2
      position: sticky
      top: 0
      background: $white
      padding-left: 1.25rem
      height: 3rem
      border-bottom: 1px solid $gray-2

  tbody
    .RowAction
      position: relative
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      min-height: 2rem
      height: 100%

      svg path
      fill: $primary

      .dropdown
        +shadow-1
        position: absolute
        z-index: 10
        top: 100%
        right: 0
        padding: 1rem 1.5rem
        background: $white
        border-radius: 4px

        .action
          cursor: pointer
          font-weight: bold
          font-size: 14px
          line-height: 30px
          color: $gray-4
          white-space: nowrap
          transition: color 200ms

          &:hover
            color: $primary

  tbody, tfoot

    tr:last-child
      td
        border-bottom: none

    td
      font-size: 12px
      padding-left: 1.25rem
      min-height: 1.5rem
      border-bottom: 1px solid mix($gray-1, $gray-2)
      padding:
        top: 0.75rem
        bottom: 0.75rem

      path, rect
        fill: $black

  tfoot
    position: sticky
    bottom: 0
    background-color: $white
    td
      padding-left: 1.25rem
      border:
        top: 1px solid mix($gray-1, $gray-2)
      padding:
        top: 0.75rem
        bottom: 0.75rem
