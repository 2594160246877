@import '../constants.sass'

.ModalForm
  position: relative

  .title
    border-bottom: 1px solid $gray-2
    margin-bottom: 2rem

    h1
      font-style: normal
      font-weight: bold
      font-size: 18px
      line-height: 25px

      text-align: center
      font-feature-settings: 'pnum' on, 'onum' on

      color: $black

      margin:
        top: -0.5rem
        bottom: 1.5rem

  .close
    cursor: pointer
    position: absolute
    top: 0.125rem
    right: 0

    >svg path
      transition: fill 200ms
      fill: $primary

    &:hover
      >svg path
        fill: $black

  .fields
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    .field-width-50
      width: calc(50% - 1rem)

    hr
      border: none
      height: 1px
      background-color: $gray-3
      width: 100%
      margin: 0.5em 0 2.5em

  .help-text
    color: $gray-3
    margin-bottom: 2rem

  .actions
    display: flex
    justify-content: space-between
    margin-top: 1.5rem
    border-top: 1px solid $gray-2
    padding-top: 1.5rem
