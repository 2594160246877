@import constants

.copy-to-clipboard
  background: $gray-1
  height: 3rem
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: .5rem
  overflow: hidden

  & .copy-to-clipboard-text
    width: 100%
    flex: 1 0 1fr
    white-space: nowrap
    overflow: hidden
    border: none
    color: $black
    font-size: 16px
    cursor: text
    background-color: transparent

  & .copy-to-clipboard-button
    text-transform: uppercase
    min-width: 7rem
    color: $primary
    flex: 0 0 auto
    cursor: pointer
    background-color: transparent
    border: none
    font-weight: 700
    padding-left: .8rem
    letter-spacing: 0.12em

    &[disabled]
      color: $gray-4
      cursor: default

    &:hover
      color: $primary-darker

    &[disabled]:hover
      color: $gray-4
