@import '../components/constants.sass'

.SsoProviders
	width: 100%
	
	.provider
		display: flex
		align-items: center
		justify-content: space-between

		padding: 7px 0

	.indicator
		height: .6rem
		width: .6rem
		border-radius: .6rem
		background-color: $valid

		&.inactive
			background-color: $gray-3

	.btn-sso 

		display: flex
		align-items: center
		justify-content: flex-start

		.label 
			display: flex
			align-items: center
			justify-content: flex-start

		svg 
			height: 1.5em
			width: 1.5em
			margin: 0 10px

		width:100%
		max-width: 20rem
		text-align: left
