@import "../../../../components/constants"
@import "../../../../utils"

.DeskSettingsForm
  .Card
    min-width: 36rem

    .licenses-info-list
      color: $gray-4
      font-size: 14px
      font-weight: 400

.FieldWrapper.horizontal-field
  flex-direction: row
  flex-wrap: wrap
  align-items: center
  justify-content: space-between

  & > div
    max-width: 40%

  label.Label
    max-width: 60%
    margin-bottom: 0
    margin-right: 1rem

  .error-text
    flex-basis: 100%
    width: 100%
