@import ../../../components/constants

.MobileSettings
  display: flex
  flex-direction: column

  .body
    flex-grow: 1
    background: var(--background-mobile)
    padding: 0 1rem

    .input
      display: flex
      padding: 0.8rem
      background: $white
      margin-bottom: 0.5rem

      .text
        flex-grow: 1

        .name
          margin-bottom: 0.8rem

        .helper-text
          font-size: $font-size-small
          color: $gray-4
