@import ../../constants
.TimePickerWrapper
  width: 100%
  position: relative

  input.TimePicker
    width: 100%
    min-width: 6.5rem

    background: $gray-1
    border: 0
    padding: 8px 12px
    font-style: normal
    font-weight: normal
    font-size: 1rem
    line-height: 2rem
    font-feature-settings: 'tnum' on, 'onum' on
    color: $black
    height: 3rem
    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

    &:focus
      outline: none

    &::-webkit-calendar-picker-indicator
      position: absolute
      top: 0
      right: 0
      bottom: 0
      width: 2rem
      height: auto
      color: transparent
      background: transparent
      cursor: pointer

  .clock-icon
    position: absolute
    height: auto
    width: auto
    right: .75rem
    top: 50%
    transform: translateY(-50%)
    pointer-events: none

    path
      fill: $primary

  &.disabled
    .clock-icon
      path
        fill: $gray-3

  &.error
    +error-border
