@import ../../components/constants

.Loading
  display: flex
  align-items: center
  background: $primary
  height: var(--view-height-safe)

  .body
    text-align: center
    flex-grow: 1

    .loader
      color: $white

      svg path
        stroke: $white

      svg circle
        stroke: $white
