@import ../constants

.CategoryRow
  margin-top: 0.8rem
  margin-bottom: 1rem

  .label-row
    display: flex
    flex-direction: column
    align-items: flex-start
    margin-bottom: 0.5rem

    .label
      font-size: $font-size-small
      text-transform: uppercase
      letter-spacing: 0.8px
      padding: 0.25rem 0.5rem
