@import '../../components/constants'

.ShareableHeader
  position: relative
  display: flex
  align-items: center
  font-weight: 500
  font-size: 16px
  line-height: 27px
  min-height: var(--headerHeight)
  height: var(--headerHeight)
  background-color: $white
  box-shadow: 12px -1px 15px rgba(100, 100, 100, .1)
  overflow: hidden
  @media (min-width: 768px)
    font-size: 20px

  .logo-wrapper
    display: flex
    flex: 0 0 auto
    justify-content: flex-start
    align-items: center
    width: #{"min(max(8rem, 30vw), var(--siebarWidth))"}
    height: calc(var(--headerHeight) - 2rem)
    margin-left: 3rem

    .logo
      display: flex
      align-items: center
      height: 100%

      img
        height: 70%

  .element
    flex: 0 1
    text-overflow: ellipsis
    white-space: nowrap
    min-width: 0
    overflow: hidden
    & + .element
      margin-left: .5rem
      @media (min-width: 768px)
        margin-left: 1rem
      @media (min-width: 1400px)
        margin-left: 3.5rem

  .time
    margin-left: 2rem
    @media (min-width: 1400px)
      margin-left: 2rem
  .end-element
    flex: 1 0 auto
    justify-self: flex-end
    display: flex
    flex-direction: row
    justify-content: flex-end
    padding-right: 1rem
    @media (min-width: 1400px)
      padding-right: 2rem
