@import '../constants'

.WeekNav, .DayNav
  position: relative
  display: flex
  justify-content: flex-start
  align-items: center
  height: 34px

  .DatePickerInput
    position: absolute
    top: 2rem
    width: 0
    height: 0

    .react-datepicker__input-container
      width: 0
      height: 0
      display: none

    .calendar-icon
      display: none

  & > div
    cursor: pointer
    margin:
      left: 0.25rem
      right: 0.25rem

    path
      transition: fill 200ms
      fill: $primary

    &:hover
      path
        transition: fill 100ms
        fill: $black

    &.prev, &.week, &.next, &.today
      display: flex

  & > div.disabled
    path
      fill: $gray-3
