@import ../../constants

.RadioGroup
  display: flex
  align-items: center

  &.vertical
    flex-direction: column
    justify-content: start
    align-items: baseline

    .react-tooltip-wrapper
      display: block

    .Radio:not(:first-child), .react-tooltip-wrapper
      margin-top: 0.5rem

  &.horizontal
    flex-wrap: wrap

    .Radio:not(:first-child), .react-tooltip-wrapper
      margin-left: 1.5rem

.Radio
  color: $black
  display: inline-flex
  align-items: center
  cursor: pointer
  user-select: none
  position: relative

  .RadioLabel
    margin-bottom: 0
    margin-left: 1rem
    cursor: pointer

  input[type="radio"]
    appearance: none
    -webkit-appearance: none
    -moz-appearance: none
    margin: 0
    padding: 0

  svg.lock
    margin-left: 1rem
    width: 1.2rem
    height: 1.2rem

  &::before
    content: ""
    border-radius: 100%
    width: 1rem
    height: 1rem
    background-color: mix($gray-1, $gray-2)
    +inner-shadow-2

  &.checked, &.checked:hover
    &::before
      background-color: $primary
      transition: background-color 200ms

  &:hover
    &::before
      background-color: mix($gray-1, $gray-3, 75%)
      transition: background-color 100ms

  &.disabled
    cursor: not-allowed

    &::before
      background-color: mix($gray-1, $gray-2)

    &.checked::before
      background-color: $gray-3

    .RadioLabel
      color: $gray-3
      cursor: not-allowed
