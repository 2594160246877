@import "../../../../utils"

.device-preview-container
  display: flex
  justify-content: center
  align-items: flex-end

  .device-preview
    position: relative

    &.six-inch
      width: toRem(118)

      .device-preview-interface
        top: toRem(12)
        left: toRem(13)
        right: toRem(13)

        img
          border-radius: toRem(2)

    &.nine-inch
      width: toRem(124)

      .device-preview-interface
        top: toRem(13)
        left: toRem(14)
        right: toRem(14)

        img
          border-radius: toRem(2)

    &.thirteen-inch
      width: toRem(130)

      .device-preview-interface
        padding: toRem(5)

    &.thirty-two-inch
      width: toRem(140)

      .device-preview-interface
        padding: toRem(6)

    &.display
      width: toRem(150)

      .device-preview-interface
        padding: toRem(3)
        height: toRem(84)
        overflow: hidden

    &.tablet
      width: toRem(118)

    .device-preview-frame
      position: relative

    .device-preview-interface
      position: absolute
      top: 0
      left: 0
      right: 0

    img
      width: 100%
