@import ../../components/constants

.Document
    .DocumentView
        margin-top: 2rem
        margin-bottom: 2rem
        margin-left: 5rem
        margin-right: 5rem

        img
            max-width: 100%
            max-height: 100%
            object-fit: cover

        @media (max-width: 480px)
            margin-left: 1rem
            margin-right: 1rem

    .SignatureInput
        width: 21rem
