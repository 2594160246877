@import '../constants'

.QuickSlots
  font-size: 0.75rem

  span.separator
    color: $gray-3
    margin:
      left: 0.25rem
      right: 0.25rem

  span.slot
    cursor: pointer
    color: $primary
