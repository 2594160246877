@import ../../../utils
@import ../../constants

.PrinterSettingsForm
  .PageForm
    width: 100%

    .fields
      flex-wrap: nowrap

      .VisitorBadgeFieldsWrapper
        width: toRem(336)

      .VisitorBadgeWrapper
        display: flex
        flex-direction: column
        margin-left: toRem(30)

        .Border
          border: 1px solid $black

    .FieldWrapper
      margin-bottom: toRem(32)
