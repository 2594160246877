@import ../constants

.InviteRow
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  display: flex
  background: white
  border-left: 3px solid white
  padding: 0.5rem
  margin-bottom: 0.5rem

  .resource-info
    flex-grow: 1

    .invite-title
      font-weight: bold
      margin-bottom: 0.25rem

    .aux-info
      font-size: $font-size-small
      color: $gray-4
      text-overflow: ellipsis
      overflow: hidden
      width: 16rem
      white-space: nowrap

  .resource-icon
    width: 2rem
    display: flex
    justify-content: center
    align-items: center

    svg path
      fill: $gray-9
