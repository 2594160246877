@import '../../components/constants'

.VisitorAppointmentForm
    width: 100%

    .fields
        display: flex
        flex-wrap: wrap
        align-items: flex-start
        justify-content: space-between

    .Card
        padding: 2rem
        width: 100%
        max-width: 42rem

    .Label
        display: block
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 19px

        color: $gray-4

        margin-bottom: 1rem

    .Field
        width: calc(50% - 1rem)
        margin-bottom: 1.5rem

        @media screen and (max-width: 39.99em)
            width: 100%

        @media screen and (min-width: 40em)
            width: calc(50% - 1rem)

        .dual-inputs
            input
                width: calc(50% - 1rem)

        input
            +inner-shadow-2

            width: 100%
            padding: 8px 12px

            font-style: normal
            font-weight: normal
            font-size: 16px
            line-height: 22px

            font-feature-settings: 'tnum' on, 'onum' on

            color: $black
            background-color: $gray-1
            border: 0

            transition: background-color 200ms
            cursor: pointer

            &:hover, &:focus
                background-color: mix($gray-1, $gray-2, 66%)
                transition: background-color 100ms
                outline: none

    .actions
        display: flex
        align-items: center
        justify-content: flex-start

        .Button:first-child
            margin-right: 1rem

        .Button
            +shadow-1
