@import ../../components/constants

.TabletStatus
    display: flex
    gap: 0.5rem

    .status
        display: flex

        .icon
            width: 24px
            height: 24px
            &.warning
                svg
                    path
                        fill: #FF0003

        .label
            font-weight: 400
            font-size: 9px
            line-height: 24px
            color: $black
