@import '../../../components/constants'

.SettingsDocuments
    display: flex

    .DocumentStatus
        position: relative
        padding-left: 10px

        &.published
            &::before
                background: $valid

        &.unpublished
            &::before
                background: $danger

        &:before
            position: absolute
            content: ""
            border-radius: 100%
            width: 4px
            height: 4px
            left: 0
            top: 50%
            transform: translateY(-50%)

    .DocumentsTableNoView
        display: flex
        flex-direction: column
        width: 100%

        .DocumentsTableNoViewTop
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            gap: 1rem
            padding: 40px 20px
            width: 100% !important
            font-size: 16px

        .DocumentsTableNoViewBottom
            display: flex
            align-items: center
            justify-content: center
            gap: 0.5rem
            padding: 3rem
            background-color: #FEFFDE
            font-weight: bold
            font-size: 16px
            color: $gray-3
