@import './../constants.sass'

.asset-type-form
  width: 26rem

  .description
    width: 100%

  .icon-change-button
    border: none
    background: none
    padding: 0
    font-size: 12px
    font-weight: 700
    color: $primary
    margin-top: 1rem
    cursor: pointer

    &:hover
      color: $primary-darker
