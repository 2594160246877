@import ../../../../components/constants

.CreditCardForm
  .Loader
    display: flex
    justify-content: center
    align-items: center
    width: 32rem
    height: 15rem

  &__close
    +button-unset
    display: block
    width: 1.5rem
    margin-left: auto
