@import ../constants

.Popup
  position: fixed
  bottom: 6rem
  left: 0
  right: 0
  background: white
  margin: 0 0.5rem
  border: 1px solid black
  box-shadow: 0.15rem 0.15rem 0 black
  display: none
  z-index: 100

  &.open
    display: block

  &.high
    bottom: calc(9rem + var(--view-padding-bottom-safe))

  &.medium
    bottom: calc(6rem + var(--view-padding-bottom-safe))

  &.low
    bottom: calc(2rem + var(--view-padding-bottom-safe))

.PopupButton
  display: flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  padding: 0.5rem
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  font-weight: 700

  &.noIcon
    padding-right: 2rem

  span
    padding: 0 1rem

  span.label
    padding: 0 0.5rem
    font-size: 0.8rem

  &.blue
    color: white
    background: $primary

    svg path
      fill: white

  &.blue:hover
    background: $primary-darker

  &.blue:active
    background: $primary-darker

  &.gray
    color: black
    background: $gray-3

    svg path
      fill: black

  &.gray:hover
    background: $gray-4

  &.gray:active
    background: $gray-4

  &.small
    height: 3rem
    width: 3rem

  &.disabled
    cursor: default
    background: $gray-4
