@import ../../../components/constants

.VisitorDetail
  display: flex
  flex-direction: column

  .head
    background: white

    .icon
      display: flex
      justify-content: center
      align-items: flex-start
      height: 8rem

      div
        padding: 0.5rem

        svg
          width: 4rem
          height: 4rem

        svg path
          fill: black

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .main-data
      margin: 2rem 1rem 1rem 1rem

      .info
        display: flex
        font-size: small

        .type
          color: $gray-4
          flex-grow: 1

        .status
          background: $gray-text-background
          padding: 0.1rem 0.5rem

      .name
        color: black
        font-size: $font-size-big
        font-weight: bold
        padding-bottom: 0.3rem

    .details
      display: flex
      flex-direction: column
      flex-grow: 1
      margin-bottom: 3rem

      .detail-row
        background: white
        border-bottom: 1px solid $gray-2
        padding: 0.8rem 1rem
        color: $gray-5
        display: flex

        .organizer
          text-overflow: ellipsis
          overflow: hidden
          width: 16rem

        .attendees
          text-overflow: ellipsis
          overflow: hidden
          width: 16rem

        .time-info
          font-size: $font-size-small

        svg
          width: 24px
          height: 24px
          margin-right: 1rem

        svg path
          fill: black

        a
          display: flex

        a svg path
          fill: $primary

      .actions-row
        display: flex
        flex-direction: column
        justify-content: flex-end
        flex-grow: 1
        padding: 0 1rem

    .loading
      margin: 1rem 0
