.GuardDisclaimer
  display: flex
  flex-direction: column
  width: 100vw
  height: var(--view-height-safe)
  justify-content: center
  align-items: center
  text-align: center
  padding: 1.5rem

  .logo

    img
      width: 3rem
      height: auto

  p
    max-width: 30rem

.UserConsentDisclaimer
  .links
    font-size: 1.25rem
