.Loader
  text-align: center

  &.small svg
    height: 20px
    width: 20px

  &.large svg
    height: 52px
    width: 52px

  &.fullScreen
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
