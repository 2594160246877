@import '../constants.sass'

.PageForm
  .Card
    min-width: 42rem
    padding: 0

    .PageHeader
      padding: 1.5rem 2rem
      background: $gray-7
      width: 100%

    .fields
      padding: 2rem
      width: 100%
      display: flex
      flex-wrap: wrap
      justify-content: space-between

      .field-width-50
        width: calc(50% - 1rem)

      hr
        border: none
        height: 1px
        background-color: $gray-3
        width: 100%
        margin: 0.5em 0 2.5em

.PageActions
  margin-top: 1.5rem
  display: flex
  align-items: center
  gap: 2rem

  .spacer
    flex-grow: 1
