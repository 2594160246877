@import './constants'

.simple-map-container
  position: relative
  height: 100%


.simple-map
  height: 100%
  --seat-scale: 1
  display: flex
  align-items: center
  justify-content: center
