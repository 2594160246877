@import '../../../components/constants'
@import '../../../utils'

.asset-type-item
  display: flex
  align-items: flex-start
  margin-bottom: toRem(24)

  .asset-type-icon
    width: toRem(48)
    flex: none

  .asset-type-details
    color: $gray-4
    flex: auto

    .name-wrapper
      display: flex
      align-items: center

    .name
      padding-right: 1rem

    .description, .building
      font-size: toRem(13)
      line-height: toRem(24)

    .building
      font-weight: 700

  .button-wrapper
    display: flex
    flex: none
    margin-left: toRem(32)

    button + button
      margin-left: 1.5rem
