@import '../components/constants'

.App .VisitorAdHoc.RouteView
  .View
    align-items: center

  .VisitorAppointmentForm
    width: unset

  .Intro
    color: $gray-4

  .Heading, .Intro
    max-width: 42rem
    width: 100%
    justify-content: flex-start

  svg.lock
    display: none

.RouteView.MeetingRequested
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.MeetingRequested
  .Screen
    width: 100%
    margin: 0
    padding-left: 1rem
    padding-right: 1rem

    &.isConstrained
      max-width: 42rem !important

    .MeetingRequestedResult
      p
        text-align: center

.App .RouteView .View.MeetingRequestedResult
  align-items: center
