@import '../../constants'

.SubscriptionCard
  min-width: 20rem
  width: 100%

  &.Card .card-content
    padding-bottom: 0

    .card-content-body
      display: grid
      grid-template-columns: 1fr 4rem

  .SubscriptionCard__grid
    display: flex
    flex-wrap: wrap

  .plan-icon
    margin-left: auto
    width: 3rem
    height: 3rem

    path
      fill: $gray-2

  .subscription-header
    display: flex
    align-items: center
    justify-content: space-between

  .plan-info
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 1.5rem

    .plan-info-title
      font-size: 1rem
      font-weight: 700
      color: $black
      line-height: 1.5rem
      margin-bottom: .5rem

    .plan-info-desc
      font-weight: 700
      color: $gray-3
      font-size: .75rem
      line-height: 1rem
      text-transform: uppercase

  .subscription-group-description, .subscription-details
    min-width: 12rem
    flex-grow: 1
    flex-shrink: 0
    margin-bottom: 3rem

    .licenses, .active
      font-size: 1.125rem
      line-height: 1.5rem

      .Button.link
        font-weight: 400
        line-height: 1.5rem

    .active
      margin-bottom: .5rem

    .small-details
      font-size: .625rem
      line-height: 0.75rem

    .small-details ~ .small-details
      margin-top: .5rem

    .subscription-details-modify
      margin-top: 2rem
