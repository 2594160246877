body
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background: var(--background-mobile)
  margin: 0

body, input, button, textarea
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

*
  box-sizing: border-box

body.scanner-active
  background: transparent
