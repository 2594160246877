@import '../../../../components/constants'
@import '../../../../utils'

.RoomSubscription
  min-width: auto

  .card-content
    .card-content-body
      display: block

      .info
        margin-bottom: toRem(24)

        .title
          font-size: toRem(24)
          line-height: toRem(32)
          margin-bottom: toRem(8)

        .type
          font-size: toRem(12)
          line-height: toRem(16)
          text-transform: uppercase
          font-weight: 700
          color: $gray-3

      .licenses
        div
          font-size: toRem(18)
          line-height: toRem(24)

      .actions
        margin-top: toRem(24)

        a
          color: $primary
          font-weight: 700
