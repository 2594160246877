.Screen
  display: flex
  margin:
    left: 1rem
    right: 1rem

  .sidebar
    padding-top: 1.25rem

  .content
    flex-grow: 1
    padding-top: 1.25rem
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
