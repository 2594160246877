@import '../components/constants'

.App .VisitorCheckIn.RouteView
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  .View
    align-items: center

  .VisitorAppointmentForm
    width: unset

  .Intro
    color: $gray-4

  .Heading, .Intro
    max-width: 42rem
    width: 100%
    justify-content: flex-start

  svg.lock
    display: none

.RouteView.CheckedIn
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.CheckedIn
  .Screen
    width: 100%
    margin: 0
    padding-left: 1rem
    padding-right: 1rem

    &.isConstrained
      max-width: 42rem !important

    .CheckedInResult
      p
        text-align: center

.App .RouteView .View.CheckedInResult
  align-items: center
