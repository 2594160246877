@import ../../constants
.horizontal-list-selector
  margin-left: 1rem
  margin-top: 1rem

  & + .horizontal-list-selector
    margin-top: 1.5rem

  &:last-child
    margin-bottom: 1rem

  .label
    text-transform: uppercase
    letter-spacing: 0.8px
    font-size: 10pt
    margin-bottom: 1rem

  .horizontal-scroll
    display: flex
    flex-direction: row
    max-width: 100%
    overflow-x: auto
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    .list-item
      cursor: pointer
      padding: .75rem 1rem
      border: 1px solid $gray-2
      white-space: nowrap

      & + .list-item
        margin-left: .5rem

      &.selected
        border-color: $primary
        background-color: $blue-text-background
        color: $primary
