@import '../../../../components/constants'
@import '../../../../utils'

.SettingsRooms
    .Intro
        display: flex
        align-items: center

        .buttons
            display: flex
            flex: 1 0 auto
            margin-left: toRem(16)
            justify-content: flex-end
            gap: toRem(16)

    .Filters
        .Dropdown, .DropdownMulti, .Search
            .control, input
                box-shadow: none
                border-color: transparent

        .Search
            input
                max-width: toRem(176)
                padding-right: toRem(50)

    .TableNoView
        display: flex
        flex-direction: column
        width: 100%

        &Top
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            flex-grow: 1
            padding: toRem(40) toRem(20)
            width: 100% !important

            svg
                width: toRem(48)
                height: toRem(48)
                margin-bottom: toRem(12)

                path
                    fill: #808080

            p
                font-weight: 400
                font-size: toRem(14)
                line-height: toRem(24)
                margin: 0
                color: $gray-4

        &Bottom
            display: flex
            align-items: center
            justify-content: center
            gap: toRem(8)
            padding: toRem(48)
            background-color: #FEFFDE
            font-weight: bold
            font-size: toRem(16)
            color: $gray-3

            &Button
                display: inline
                background: none
                border: unset
                padding: 0
                font-weight: 900
                color: $primary
                font-size: toRem(16)
                cursor: pointer
                text-decoration: none
                letter-spacing: normal
                text-transform: none

    .Table
        td.DevicesCell
            span
                display: inline-block
                width: auto
                margin-right: toRem(5)
