@import ../constants

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

.Drawer
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1201
  background: rgba(0, 0, 0, 0.5)
  padding-bottom: var(--view-padding-bottom-safe)
  display: none

  &.open
    display: block
    animation: fadeIn 0.3s

  .drawer-content
    position: fixed
    left: 0
    right: 0
    bottom: 0
