.DocumentEdit

  .DocumentWrapper
    .DocumentPreview
      .DocumentViewCard
        padding: 3rem
        width: 42rem
        height: 100%
        overflow: auto
        scrollbar-width: thin

    .DocumentForm
      &.hidden
        visibility: hidden

  .loading
    align-self: center
