@import "./../components/constants"
    
.CheckIns
    width: 21rem

    .CheckIn
        padding: 1rem

        &:not(:last-child)
            padding-bottom: 1rem
            border-bottom: 1px solid $gray-3

        .Name
            position: relative
            font-size: 1rem
            font-weight: 900
            margin-bottom: 0.5rem

        .Description
            font-size: 0.8rem
            color: $gray-8
            margin-bottom: 2rem

        .ResourceIcon
            position: absolute
            top: 0
            right: 0
            transform: translate(0, 0)

            path
                fill: $black