.CompanyDetails
  .company-info
    display: flex
    column-gap: 4rem
    line-height: 1.5rem
    margin-bottom: 1.25rem

    p
      margin-top: 0
      margin-bottom: .75rem

      &:last-of-type
        margin-bottom: 0
