@import ../../../components/constants

.ReservationMap
  display: flex
  flex-direction: column

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .content
      flex-grow: 1
      display: flex
      flex-direction: column

      .reservation-info
        text-align: center
        margin-bottom: 1rem

      .map
        flex-grow: 1
        display: flex
        flex-direction: column

        .MapContainer
          flex-grow: 1

      .spacer
        padding-bottom: var(--view-padding-bottom-safe)
