.LimitOccupancyDecider
  .NumberField
    display: flex
    flex-direction: row
    align-items: center
    margin-top: 1.5rem
    width: 100%

    .Input
      width: 4.5rem
      margin-right: 1rem

    .Label
      margin-bottom: 0
