@import ./../../constants

.Accordion
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 1rem
  overflow: hidden

  .Accordion__header
    +button-unset
    display: flex
    align-items: center
    color: $primary
    font-size: 0.875rem
    font-style: normal
    font-weight: 700
    line-height: 1.5rem
    text-align: left
    cursor: pointer

    .Accordion__header__content
      flex: 0 1 auto
      overflow: hidden

    .Accordion__header__chevron
      flex: 0 0 1rem
      margin-left: .62rem
      transition: transform 0.3s ease
      path
        fill: $primary

    &.isOpened
      .Accordion__header__chevron
        transform: scaleY(-1)

  .Accordion__content
    margin-top: .75rem
