@import ../../../../components/constants

.InvoiceList
  a
    font-weight: 700
    font-size: 0.875rem

  .InvoiceTable
    background: none
    box-shadow: none
    margin-bottom: 0.375rem

    & thead th
      height: auto
      background: none
      border-bottom: none
      padding-left: 0
      padding-bottom: 0.375rem

    & tbody td
      border-bottom: none
      font-size: 0.875rem
      line-height: 1.5rem
      padding-bottom: 0.375rem
      padding-top: 0.375rem
      padding-left: 0

    tr.clickable-row
      background: none
      &:hover
        background-color: $gray-2
