@import ../../components/constants
.Book
  display: flex
  flex-direction: column

  .sub-page
    flex-grow: 1
    background: var(--background-mobile)

    .loading
      margin: 1rem 0
