@import ../constants
@import ../../utils

.HealthSettingsForm
  width: 100%

  .Subtext
    font-size: toRem(12)
    font-style: normal
    font-weight: 400
    line-height: 18px
    color: $gray-4

  &.isInvalid
    textarea:invalid
      background: mix($gray-1, $danger, 90%) !important

  .fields
    hr
      margin: 1em 0!important

    .FieldWrapper
      &:not(:first-child)
        margin: 1.5rem 0

    .TextAreaField
      textarea
        margin-top: 0.5rem

    .field-width-100
      width: 100%
      margin-bottom: 1em

    .CheckboxWrapper
      &:not(:last-child)
        margin-bottom: 2em

    .indent
      padding-left: calc(18px + 1rem)

    label
      margin-bottom: .5em

  label.Label
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 19px
    color: $gray-4

  input[type=date], input[type=text],
  input[type=number], input[type=email],
  textarea
    width: 100%
    background: $gray-1
    border: 0
    padding: 8px 12px
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
    font-feature-settings: 'tnum' on, 'onum' on
    color: $black

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

      svg path
      fill: $black

  input[type=text], input[type=email]
    cursor: text

  input[type=date]:focus, input[type=text]:focus,
  input[type=number]:focus, input[type=email]:focus,
  textarea
    outline: none

  .disabled
    opacity: 0.5
    pointer-events: none
