@import ../../../../components/constants
.PrepaidCodeInfo
  display: flex
  flex-direction: row
  align-items: flex-start
  font-size: 0.875rem
  line-height: 1.5rem
  margin-top: 1.5rem

  &__index
    width: 2.25rem
    flex: 0 0 auto

  &__details
    p
      font-size: 0.625rem
      font-style: normal
      font-weight: 400
      line-height: 0.75rem
