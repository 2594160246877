@import './../constants.sass'

.BrandingForm
  .Card
    width: 42rem

    .fields
      font-size: $font-size-normal
      display: block

      .field-description
        display: flex

      .info
        margin-left: 2rem
        width: 28rem

        div
          margin-bottom: 1rem
