@import ../../../components/constants

.SingleSuggest
  display: flex
  flex-direction: column
  background: var(--background-mobile)

  .body
    padding: 0 1rem
    padding-bottom: 1rem
    display: flex
    flex-direction: column
    flex-grow: 1

    .title
      font-size: $font-size-title
      font-weight: bold
      margin-bottom: 1.5rem
