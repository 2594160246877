@import ../../../components/constants

.DoneNew
  text-align: center
  display: flex
  flex-direction: column

  .TopNav
    background: white

  .upper
    background: white
    height: 60%

    svg
      width: 3rem
      height: 3rem
      @media screen and (min-height: 700px)
        width: 4rem
        height: 4rem

    svg path
      fill: black

    .wrapper
      padding: .5rem 2rem
      padding-top: 0
      height: 100%
      display: flex
      flex-direction: column
      align-items: center
      justify-content: stretch
      @media screen and (min-height: 700px)
        padding: 2rem

    .title
      font-size: $font-size-title
      font-weight: bold
      margin: 0rem 0 .5rem 0
      @media screen and (min-height: 700px)
        margin: 1.5rem 0 2rem 0

    .description
      padding: 0 1rem

  .lower
    padding: 1rem
    height: 40%
    position: relative

    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: center
    @media screen and (min-height: 700px)
      padding: 2rem

  .action
    padding: 1rem
    padding-top: 0
    @media screen and (min-height: 700px)
      padding: 2rem
    .book-asset
      margin-bottom: 0.25rem

.Done
  display: flex
  flex-direction: column
  max-height: calc(var(--view-height-safe) - var(--view-padding-top-safe))

  .head
    background: white

    .icon
      display: flex
      justify-content: center
      align-items: flex-start
      height: 6rem
      flex-shrink: 1
      @media screen and (min-height: 700px)
        height: 8rem
      div
        padding: 0.5rem

        svg
          width: 4rem
          height: 4rem

        svg path
          fill: black

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .details
      flex-grow: 1
      text-align: center

      .title
        font-size: $font-size-title
        font-weight: bold
        margin: 1rem 0 1rem 0
        @media screen and (min-height: 700px)
          margin: 3rem 0 1rem 0

      .info
        color: $gray-5
        padding: 0 2rem

  .action
    padding: 1rem 1rem calc(1rem + var(--view-padding-bottom-safe)) 1rem
