@import ../../../components/constants

.Time
  display: flex
  flex-direction: column
  background: var(--background-mobile)

  .body
    flex-grow: 1
    padding: 0 1rem

    .info
      margin-bottom: 0.5rem
      font-size: $font-size-title
      font-weight: bold

      span
        cursor: pointer
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none
        color: $primary

    .instructions
      color: $gray-5
      margin-bottom: 2rem

    .next-button
      width: 100%
