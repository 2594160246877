@import './constants'

@keyframes blinking
  from
    opacity: 0.5

  to
    opacity: 1

.map-buttons

  span
    +shadow-2
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    background: white
    transition: background 300ms
    margin: 0.5rem

    svg
      width: 1.25rem
      height: 1.25rem

      rect, path
        fill: blue

    &:hover
      background: $gray-2

      svg
        rect
          fill: $black

.ZoomButtons
  position: absolute
  top: 0
  right: 0
  z-index: 2
  display: flex

  span
    width: 2rem
    height: 2rem
    border-radius: 2rem
    margin-left: 0

    svg
      rect
        fill: $primary

$btn-spacing: 1.25rem

.PanButtons
  position: absolute
  top: 2.5rem
  right: 0.35rem
  z-index: 2

  span
    width: 1.25rem
    height: 1.25rem
    border-radius: 1.25rem

    svg
      path
        fill: $primary

  span.up
    position: absolute
    top: 0
    right: $btn-spacing

  span.right
    position: absolute
    top: $btn-spacing
    right: 0

  span.down
    position: absolute
    top: $btn-spacing * 2
    right: $btn-spacing

  span.left
    position: absolute
    top: $btn-spacing
    right: $btn-spacing * 2

.MapContainer
  position: relative

.Map
  --seat-scale: 1
  display: flex
  align-items: center
  justify-content: center
