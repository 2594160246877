@import ../../../components/constants

.RouteView.UserDetails.isConstrained
  width: 32rem

  .View
    align-items: center

    .Heading
      margin-bottom: 1rem

    .Intro
      text-align: center
      margin-bottom: 2rem

    .PageForm
      .Card
        min-width: 30rem

      .PageActions
        justify-content: space-between

        .spacer
          display: none

    .UserDetails__picture
      display: block
      .ImageSelector
        width: auto
        margin-right: 2rem

      .Label
        float: right
        width: 14rem
        margin-bottom: 0

        p
          margin-top: 0

        .picture__text
          font-size: .75rem
          font-weight: 400
          color: $gray-4
