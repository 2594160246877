@import ../constants

.UsersCSVForm
  position: relative
  width: 28rem

  .actions
    flex-direction: column
    align-items: center

  .title
    border-bottom: 1px solid $gray-2
    margin-bottom: 2rem

  .close
    cursor: pointer
    position: absolute
    top: 0.125rem
    right: 0

    svg path
      transition: fill 200ms

    &:hover
      svg path
        fill: $black

  .light-text
    color: $gray-4

  .button-with-hint
    display: flex
    justify-content: flex-start
    align-items: center

    .Button
      +shadow-1
      background-color: $gray-2
      .label
        display: flex
        align-items: center
        justify-content: center

    svg
      margin-right: 8px
      width: 20px

    .Button:hover
      background-color: $black
      svg
        fill: white

    .Button+span
      margin-left: 0.25rem
      color: $gray-3
