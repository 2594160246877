@import '../../components/constants'

.Profile
  .Intro
    max-width: 36rem

  .Card
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    min-width: 36rem

  .SsoProvidersCard
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

  .row
    margin: 1rem 0

  .user-departments
    color: $gray-3

  .company-name
    display: flex

  .company-edit
    cursor: pointer

    svg
      height: 1.25rem
      width: 1.25rem
      margin-left: 0.5rem
      path
        fill: $primary

  .manager-title
    display: block
    color: $gray-3
    margin-top: 1rem

  .avatar
    font-size: 8rem

  .AvatarEllipse
    svg
      width: 6rem
      height: 6rem

      circle
        fill: var(--user-color)

  .actions
    display: flex
    align-items: center
    justify-content: flex-start

    .Button
      margin-right: 1rem
      +shadow-1
