@import ../../components/constants

.Floor
  display: flex
  flex-direction: column

  .body
    flex-grow: 1
    display: flex
    flex-direction: column
    background: white
