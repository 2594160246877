@import '../constants'

.CheckinLabel
  display: block
  font-weight: normal

  &.checked-out
    color: $gray-3

.ReservationCheckinLabel
  color: $primary

.AppointmentCheckinLabel
  color: $gray-3
