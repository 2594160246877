.VisitorContent
	display: flex
	flex-direction: column
	justify-content: center
	flex-grow: 1
	align-items: center

	.title
		font-weight: bold
		font-size: 38pt
		margin-bottom: 1rem
		max-width: 28rem
		text-align: center

		@media (max-width: 480px)
			font-size: 23pt

	.subtitle
		font-weight: bold
		font-size: 24pt
		margin-bottom: 1rem
		text-align: center
