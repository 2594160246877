@import ../../constants

.Options
  width: 100%

.Option
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 32px
  font-feature-settings: 'tnum' on, 'lnum' on
  color: $black
  padding: 8px 0

  display: flex
  align-items: center

  cursor: pointer

  position: relative

  svg.lock
    position: absolute
    top: 50%
    margin-left: 1rem
    transform: translateY(-50%)

  svg.radio
    width: 1rem
    height: 1rem
    margin-right: 1rem

    circle
      fill: mix($gray-1, $gray-2)
      +inner-shadow-2

  &.isActive, &.isActive:hover
    svg circle
      fill: $primary
      transition: fill 200ms

  &:hover
    svg circle
      transition: fill 100ms
      fill: mix($gray-1, $gray-3, 75%)
