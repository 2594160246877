@import '../../../components/constants'

.SettingsDevices

    .SettingsDevicesActiveFilters
        display: flex
        align-items: baseline
        gap: 24px
        margin: 0
        padding: 0

        .Button
            margin: 0

    .DevicesTableNoView
        display: flex
        flex-direction: column
        width: 100%

        .DevicesTableNoViewTop
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            flex-grow: 1
            padding: 40px 20px
            width: 100% !important

            svg
                margin-bottom: 15px
                width: 65px
                height: 65px
                path
                    fill: #808080

            p
                font-weight: 400
                font-size: 16px
                line-height: 24px
                margin: 0
                color: black

        .DevicesTableNoViewBottom
            display: flex
            align-items: center
            justify-content: center
            gap: 0.5rem
            padding: 3rem
            background-color: #FEFFDE
            font-weight: bold
            font-size: 16px
            color: $gray-3

            .DevicesTableNoViewBottomButton
                display: inline
                background: none
                border: unset
                padding: 0
                margin: 0 0 0 5px
                font-weight: 900
                color: $primary
                font-size: 16px
                cursor: pointer
                text-decoration: none
                letter-spacing: normal
                text-transform: none

    .Table
        td.DevicesCell
            span
                display: inline-block
                width: auto
                margin-right: 5px
