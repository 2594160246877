@import ../../constants

.filter-button
  display: flex
  justify-content: center

  button
    +button-unset
    text-align: center
    cursor: pointer
    user-select: none
    display: flex
    justify-content: center
    align-items: center
    font-size: unset

    .icon
      margin-right: 0.25rem

    svg path
      fill: black
    &.active
      color: $primary
      svg path
        fill: $primary
