@import '../../constants'

#WeekPickerPortal
  .react-datepicker__week:hover
    .react-datepicker__day
      background-color: $gray-2
      border-radius: 50%

    .react-datepicker__day--disabled
      background-color: transparent

    .react-datepicker__day--selected
      background-color: $primary
