@import ../../../components/constants

.Title
  display: flex
  flex-direction: column
  background: var(--background-mobile)

  .body
    flex-grow: 1
    padding: 0 1rem

    .info
      margin-bottom: 0.5rem
      font-size: $font-size-title
      font-weight: bold

    input
      width: 100%
      padding: 0.5rem 0
      margin: 3rem 0
      border: none
      border-bottom: 2px solid $gray-3
      outline: none
      background: var(--background-mobile)
      font-size: $font-size-normal
      -webkit-appearance: none
      border-radius: 0

    .next-button
      width: 100%
