@import ../../constants

.NewCheckbox
  .CheckboxLabel
    margin-bottom: 0
    cursor: pointer

  .control
    display: flex
    flex-direction: column
    justify-content: stretch

    .main
      font-weight: bold
      display: flex
      align-items: center
      user-select: none

    .extra
      font-weight: normal
      font-size: 14px
      line-height: 19px
      color: $gray-4
      padding:
        top: 0.5rem
        left: 2rem

  input[type=checkbox]
    cursor: pointer
    appearance: none
    display: inline-block
    width: 0.875rem
    min-width: 0.875rem
    height: 0.875rem
    line-height: 0.875rem
    padding: 0
    margin-left: 0.125rem
    margin-right: 1rem
    background-color: mix($gray-1, $gray-2)
    // transform: translateY(0.0625rem)
    transition: background-color 200ms
    +inner-shadow-2

    &:disabled
      cursor: default

      input:focus, &:hover input
      outline: none
      background-color: mix($gray-1, $gray-2, 66%)

  &.checked input[type=checkbox]
    background-color: $primary

  &.isSecondary
    .Label.CheckboxLabel
      display: flex
      align-items: center

    input[type=checkbox]
      display: none

    .checkmark
      display: inline-block
      flex-shrink: 0
      width: 1.125rem
      height: 1.125rem
      border: 1px solid $gray-4
      border-radius: 3px
      background-color: transparentize($white, 0.5)
      position: relative
      margin-right: 1rem
      transition: background-color 200ms
      & svg
        width: 17px
        height: 17px
    &:not(:checked)
      border-color: $gray-4

  &.checked
    .checkmark
      background-color: $primary
      border-color: $primary
      svg
        position: absolute
        left: 2px
        top: 2px
        width: 12px
        height: 12px
        path
          fill: $white

  &.disabled
    .CheckboxLabel
      cursor: default
    .control
      opacity: .5

    &.checked
      .checkmark
        background-color: $gray-4
        border-color: $gray-4
