@import '../../../../components/constants'
@import "../../../../utils"

.RoomDeviceForm
  width: toRem(563)

  .Card
    min-width: auto

    .device-info
      display: flex
      justify-content: space-between
      width: 100%
      margin-bottom: toRem(60)

      > div
        width: 50%

      .device-info-text
        color: $gray-4
        max-width: toRem(220)

        .title
          font-size: toRem(14)
          font-weight: 700
          margin-bottom: toRem(8)

        .description
          line-height: toRem(16)
          font-size: toRem(12)

    .PageFooter
      .UnavailableRoomsView
        display: flex
        align-items: center
        flex-direction: column
        justify-content: center
        padding: toRem(32)
        background-color: #FEFFDE
        font-size: toRem(16)

        .UnavailableRoomsViewDescription
          color: $gray-4
          font-weight: 400
          line-height: toRem(24)

        .UnavailableRoomsViewButton
          color: $primary
          font-weight: 700
          line-height: toRem(24)



