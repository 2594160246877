@import '../../constants'

.Switch
  display: flex
  gap: 1rem
  align-items: center
  width: 100%

  &.rightAlign > .switcher
    margin-left: auto

  .Label.SwitchLabel
    cursor: pointer
    margin-bottom: 0
    line-height: 1

    .SubText
      margin-left: 0.5rem
      font-weight: 400

    &.disabled
      cursor: not-allowed

  .switcher
    position: relative
    display: inline-block
    width: 40px
    height: 20px
    background-color: transparent
    border-radius: 10px
    user-select: none
    cursor: pointer

    .slider
      position: absolute
      cursor: pointer
      top: 2px
      left: 2px
      background-color: $gray-1
      transition: 0.3s
      transition-property: box-shadow, left, right
      border-radius: 8px
      width: 16px
      height: 16px

    .switcher-bg
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border-radius: 10px
      background-color: $gray-3

    input:checked ~ .slider
      left: 22px

    input:checked + .switcher-bg
      transition: 0.3s
      transition-property: box-shadow, left, right
      background-color: $primary

    input:focus + .switcher-bg
      box-shadow: 0 0 1px 2px $primary-lighter

  input
    opacity: 0
    width: 0
    height: 0

  &.disabled
    .switcher
      input:checked + .switcher-bg,  .switcher-bg
        cursor: not-allowed
        background-color: $gray-2

      .slider
        background-color: $gray-1
        cursor: not-allowed
