@import ../constants

.RepeatPicker
  padding-bottom: 2rem
  background: white

  .picker-content
    background: white

    .section-head
      display: flex
      flex-direction: column
      border-bottom: 1px solid $gray-2

    .section
      display: flex
      flex-direction: column
      padding: 0 1rem
      border-bottom: 1px solid $gray-2

      .option
        display: flex
        padding: 1rem 0
        cursor: pointer
        position: relative

        .date-input
          cursor: pointer
          position: absolute
          -webkit-appearance: none
          border-radius: 0
          width: 100%
          height: 100%
          top: 0
          left: 0
          opacity: 0
          &::-webkit-calendar-picker-indicator
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            margin: 0
            padding: 0
            cursor: pointer

        .option-name
          flex-grow: 1

        .option-check
          text-align: right
