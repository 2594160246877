@import './../../constants.sass'

.DeviceForm
  .Card
    width: 42rem

    .device-info
      display: flex
      margin: 2rem 0

      .device-image
        img
          width: 20rem

      .device-info-text
        padding-top: 3rem
        color: $gray-4

        .title
          font-weight: bold
          margin-bottom: 0.5rem

        .description
          font-size: 12px
