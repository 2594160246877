
@import '../../components/constants.sass'

.VisitorSignInFieldRow
  background-color: $white
  justify-content: space-between
  flex-direction: row
  align-items: center
  width: 100%
  display: flex

  & .VisitorSignInField
    width: 90%

    & .TextInput
      margin-top: 17px
      margin-bottom: 20px

      & input[type="text"]
        padding: 13px 16px

  .VisitorSignInFieldName
    font-weight: bolder

  .VisitorSignInFieldInfoText
    font-weight: 200
    color: $gray-3
    font-size: 14px

  .Delete
    display: flex
    margin: 0 1.5rem
    cursor: pointer

    .BtnDelete
      border: 0
      background: $white
      cursor: pointer

  .NewCheckbox
    .CheckboxLabel
      font-weight: 400
