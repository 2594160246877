@import ../../../components/constants

@keyframes desk-item-details-show
  0%
    max-height: 0
  100%
    max-height: 300px

.desk-list-wrapper
  padding-top: 2rem
  padding-bottom: 2rem
  flex: 1 1
  display: flex
  overflow: hidden

  .floor-plan-desk-list
    width: 20rem
    padding-left: 2rem
    flex-grow: 1
    overflow-y: scroll

    .desk-item
      font-size: 0.75rem
      margin-right: 1.5rem

      &:last-child
        margin-bottom: var(--desk-list-height)

      .header
        cursor: pointer
        margin-bottom: 1rem

        .title
          font-weight: 700
          display: flex
          align-items: center
          font-size: 1rem
          justify-content: space-between
          transition: color .25s ease

          .desk-name
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

          svg
            flex-shrink: 0
            path
              fill: $black
              transition: fill .25s ease

        .status
          display: flex
          max-width: 100%
          align-items: center
          color: $gray-4

          span
            text-transform: capitalize
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

          .status-dot
            width: .5rem
            height: .5rem
            border-radius: .5rem
            background-color: $secondary
            margin-right: .5rem

            &.booked
              background-color: $danger

            &.disabled
              background-color: $gray-3

      &.disabled
        .header
          .title
            color: $gray-3
          svg path
            fill: $gray-3

      .details
        max-height: 0
        overflow: hidden
        margin-bottom: 0

        &.opened
          animation: desk-item-details-show .5s
          max-height: fit-content
          visibility: visible
          margin-bottom: 1rem
        &.closed

          max-height: 0
          visibility: hidden

        .department-list
          display: flex
          gap: .5rem
          font-size: 0.75rem
          flex-wrap: wrap

          .department-item
            padding: 2px 4px
            background: $gray-2
            text-overflow: ellipsis
            white-space: nowrap
            overflow: hidden

        .amenities-list
          margin-top: .5rem
          color: $gray-3
          text-overflow: ellipsis
          overflow: hidden

        .book-desk
          margin-top: .5rem
          line-height: 1rem
          font-size: .75rem
          text-transform: uppercase
          font-weight: 700
          transition: color .25s

          &:hover
            color: $primary-darker

        .reservation-list
          .reservation-item
            +button-unset
            margin-top: .5rem
            text-align: left
            width: 100%
            line-height: 1rem
            font-size: .75rem
            display: flex
            transition: color .25s

            &:hover
              color: $primary

            .reservation-name
              flex-grow: 1
              text-overflow: ellipsis
              white-space: nowrap
              overflow: hidden

            .reservation-time
              flex-shrink: 0
              white-space: nowrap

          &.subdued
            .reservation-item
              color: $gray-5

      &.hovered, &.isOpened
        .header
          .title
            width: 100%
            color: $primary

            svg path
            fill: $primary

      &.isOpened
        .header
          .title
            svg
              transform: scaleY(-1)
