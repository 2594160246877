@import ../constants

.SearchBox
  display: flex
  background: white
  padding: 0.5rem 1rem
  margin: 0 1rem

  .search-icon
    margin-right: 1rem
    display: flex
    align-items: center

    svg path
      fill: $black

  .search-input
    flex-grow: 1

  input
    height: 100%
    width: 100%
    border: none
    outline: none
    font-size: $font-size-normal
  ::placeholder
    color: $gray-3
