@import ../../../components/constants

.Info
  display: flex
  flex-direction: column
  background: linear-gradient(to bottom, white, white 45%, var(--background-mobile) 46%, var(--background-mobile))

  .body
    flex-grow: 1
    padding: 0 1rem

    h2
      font-size: $font-size-title
      margin: 0 0 0.5rem 0

    .fields
      margin-top: 1rem

      .field
        display: flex
        padding-bottom: 1.5rem

        .data
          flex-grow: 1
          min-width: 0
          // reason for using min-width: 0 can be found on this link
          // https://css-tricks.com/flexbox-truncated-text/

          .label
            font-size: $font-size-small

          .value
            font-weight: bold
            text-overflow: ellipsis
            overflow: hidden

        .edit
          width: 3rem
          display: flex
          justify-content: flex-end
          align-items: flex-end

.LinkBox
  display: flex
  flex-direction: column
  justify-content: space-between
  border: 1px solid black
  padding: 0.3rem 1.5rem
  background: white

  a
    padding: 0.5rem 0
    margin: 0.5rem 0
    display: flex

  svg
    margin-right: 1rem

  div
    color: black
