@import ../constants

.EventRow
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  display: flex
  background: white
  border-left: 3px solid white
  padding: 0.5rem
  margin-bottom: 0.5rem

  &.isActive
    border-left: 4px solid $danger
    background: $black
    color: $white

    .resource-info
      .attendees
        color: $gray-1
      .aux-info
        color: $gray-1

    .resource-icon
      svg path
      fill: $white

  .resource-info
    flex-grow: 1

    .event-title
      font-weight: bold
      margin-bottom: 0.25rem

    .attendees, .aux-info, .diff-tz, .building-floor
      font-size: $font-size-small
      color: $gray-4
      text-overflow: ellipsis
      overflow: hidden
      width: 16rem
      white-space: nowrap

  .resource-icon
    width: 2rem
    display: flex
    justify-content: center
    align-items: center

    svg path
    fill: $gray-9
