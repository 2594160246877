@import '../../../../components/constants'
@import '../../../../utils'

.SettingsRoomsDevices
    .Intro
        display: flex
        align-items: center

        .buttons
            display: flex
            flex: 1 0 auto
            margin-left: toRem(16)
            justify-content: flex-end

    .Filters
        .Dropdown, .DropdownMulti, .Search
            .control, input
                box-shadow: none
                border-color: transparent

        .Search
            input
                max-width: toRem(176)

        .DropdownMulti.device-status-filter
            .options
                padding-top: toRem(12)
                padding-bottom: toRem(12)

                .option
                    &:not(:last-child)
                        margin-bottom: toRem(6)

                    &:nth-of-type(1), &:nth-of-type(4)
                        padding: 0 0 toRem(12) 0
                        margin-left: toRem(14)
                        margin-bottom: toRem(12)
                        border-bottom: 1px solid $gray-1

                    &:not(:nth-last-of-type())
                        padding-bottom: toRem(6)

                    .indicator
                        width: toRem(12)
                        height: toRem(12)

                    .label
                        font-weight: 400
                        padding: 0
                        max-width: toRem(140)

        .MultiActionButton.isSmall
            .Control
                font-size: toRem(14)

            .Content
                padding: toRem(12) toRem(14)

                .Option
                    padding: 0

                    &:not(:last-child)
                        margin-bottom: toRem(12)

                    .Label
                        margin: 0
                        padding: 0
                        font-weight: 400
                        line-height: toRem(24)
                        font-size: toRem(14)

    .SettingsDevicesActiveFilters
        display: flex
        align-items: baseline
        gap: toRem(24)
        margin: 0
        padding: 0

        .Button
            margin: 0

    .DevicesTableNoView
        display: flex
        flex-direction: column
        width: 100%

        .DevicesTableNoViewTop
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            flex-grow: 1
            padding: toRem(40) toRem(20)
            width: 100% !important

            svg
                width: toRem(48)
                height: toRem(48)
                margin-bottom: toRem(12)

                path
                    fill: #808080

            p
                font-weight: 400
                font-size: toRem(14)
                line-height: toRem(24)
                margin: 0
                color: $gray-4

        .DevicesTableNoViewBottom
            display: flex
            align-items: center
            justify-content: center
            gap: toRem(8)
            padding: toRem(48)
            background-color: #FEFFDE
            font-weight: bold
            font-size: toRem(16)
            color: $gray-3

            .DevicesTableNoViewBottomButton
                display: inline
                background: none
                border: unset
                padding: 0
                margin: 0 0 0 toRem(5)
                font-weight: 900
                color: $primary
                font-size: toRem(16)
                cursor: pointer
                text-decoration: none
                letter-spacing: normal
                text-transform: none

    .Table
        td.DevicesCell
            span
                display: inline-block
                width: auto
                margin-right: toRem(5)
