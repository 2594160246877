@import '../../constants'

.InputNumber
  display: flex
  align-items: center

  .InputNumberField
    flex: 1
    border: none
    text-align: center
    font-size: 16px
    font-weight: 400
    appearance: textfield
    max-width: 64px
    background: $gray-1
    height: 48px

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      appearance: none
      margin: 0

    &:focus-visible
      appearance: none
      outline: none

  button
    border: none
    padding: 2px 12px
    cursor: pointer
    color: $primary
    background: $gray-2
    display: flex
    align-items: center
    height: 100%
    svg
      width: 24px

  &.disabled
    opacity: 0.5
    pointer-events: none
