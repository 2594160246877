.App
  .RouteView.VisitorLogin
    display: flex
    flex-direction: column
    align-items: stretch
    justify-content: center


    .content
      height: 100%
      padding-top: 0
      padding-bottom: 1.25rem

    .View
      align-items: center

      .VisitorLoginForm
        width: unset

        .Label
          cursor: default

        input[type=text]
          cursor: text
          font-size: 1.5rem
          letter-spacing: 0.125rem