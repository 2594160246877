@import '../../../../components/constants'
@import "../../../../utils"


.PairRoomDeviceForm
  width: toRem(480)

  .Card
    min-width: auto

  .info
    width: 100%
    color: $gray-4
    margin-bottom: toRem(32)
    line-height: toRem(24)

  .fields
    .MultiToggle
      border-color: transparent
      background: $gray-1

      .Toggle:not(.Active)
        background: $gray-1

    .input-info
      display: flex
      flex-direction: column
      width: 100%
      margin-bottom: toRem(32)

      .text
        color: $gray-4

  .FieldWrapper.Uppercase
    input
      text-transform: uppercase
