@import '../../components/constants'

@keyframes gradient-wipe
	0%
		background-position: 0vw 0
	100%
		background-position: 200vw 0
.people-license-tooltip
	width: 30rem

.People
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start
	min-width: 50rem

	.divider-div
		margin-left: auto
		display: flex

	.Intro
		display: flex
		align-items: center
		.description
			p:first-child
				margin-top: 0
			p:last-child
				margin-bottom: 0
		.buttons
			display: flex
			flex: 1 0 auto
			margin-left: 1rem

			button:first-child
				margin-right: .5rem

	.invite-users
		max-width: 50rem
		margin-top: 1.5rem

.People .Card
	min-width: 42rem
	font-size: 0.9rem
	position: relative

	&:after
		content: ''
		display: block
		position: absolute
		width: 100%
		height: 3px
		background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
		top: 0
		left: 0
		z-index: 2
		opacity: 0
		transition: opacity 200ms
		pointer-events: none

	&:before
		content: ''
		display: block
		position: absolute
		width: 100%
		height: 3px
		background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
		bottom: -3px
		left: 0
		z-index: 2
		opacity: 0
		transition: opacity 200ms
		pointer-events: none

	&.isLoading:before, &.isLoading:after
		animation: gradient-wipe 1600ms linear infinite
		opacity: 1
		transition: opacity 800ms

	&.people
		align-self: stretch

	.person:first-child
		margin-top: 0.5rem
		margin-bottom: 1.5rem

	.person:last-child
		margin-bottom: 0.5rem

	.person
		display: flex
		align-items: center
		margin-bottom: 1.5rem

		.data
			flex-grow: 1
			display: flex
			flex-direction: column
			align-items: stretch
			justify-content: flex-start
			padding-left: 1rem
			padding-right: 1rem

			.primary
				display: flex
				align-items: center
				margin-bottom: .2rem

				.name
					text-transform: capitalize

				.role
					color: $gray-4

				.name, .role
					margin-right: 0.5em

			.secondary
				display: flex
				flex-direction: column

				.email, .last-login
					font-size: 0.8rem
					line-height: 1.7
					letter-spacing: 0.02em
					color: $gray-4

				.last-login
					color: $gray-3
