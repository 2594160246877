@import ../../../../components/constants

.PaymentMethod
  width: 35rem
  font-size: 0.875rem
  line-height: 1.5rem

  .Stepper
    margin: 2rem 0

  &__form
    width: 35rem
    &__card
      padding: 2rem 2rem 3rem
      background-color: $white
      width: 100%

    &__no-payment
      margin-bottom: 1.5rem

    .Card.old-card:first-child
      min-width: 35rem
      background-color: transparent
      box-shadow: none
      padding: 0

      .fields
        padding: 0

  &__prepaid-codes.Card
    margin-top: 1rem
    width: 35rem
    min-width: 35rem

    .PaymentMethod__prepaid-codes__title
      font-size: 0.875rem
      font-weight: 700
      line-height: 1.5rem
