@import ../constants

.BookingForm
  min-width: 38rem

  &.NotFound
    display: flex
    justify-content: center
    align-items: center
  .QuickSlots
    margin-top: 1rem
