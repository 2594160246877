@import ../../constants

.DropdownMulti .options
  max-height: 30rem
  overflow-y: auto

.Dropdown
  cursor: pointer
  position: relative
  user-select: none

  .options
    pointer-events: none
    opacity: 0
    transition: opacity 200ms

  &:hover
    .options
      pointer-events: auto
      opacity: 1
      transition: opacity 50ms
      z-index: 3

  .options
    position: absolute
    top: 1px
    left: 1px
    min-width: calc(100% - 2px)
    list-style: none
    padding-left: 0
    padding: 4px 34px 6px 0px
    border-radius: 15px
    background-color: $white
    +shadow-1

    max-height: 30rem
    overflow-y: auto

    svg
      position: absolute
      right: 14px
      top: 3px

      path
        fill: $primary

  .option
    padding: 0 0px 0px 14px

    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 24px

    font-feature-settings: 'pnum' on, 'lnum' on

    .label
      word-break: keep-all
      white-space: pre

      color: $gray-3
      transition: color 200ms

    &:hover .label
      color: $primary
      transition: color 50ms

    &.active .label
      color: $black

  .control
    display: flex
    justify-content: center
    align-items: center
    padding: 3px 10px 5px

    min-height: 34px

    background: $white
    border: 1px solid $gray-2
    box-sizing: border-box

    +inner-shadow-1

    border-radius: 16px

    transition: background-color 200ms

    .label, svg
      margin: 0 4px

    svg path
      fill: $primary

    .label
      font-style: normal
      font-weight: bold
      font-size: 14px
      line-height: 19px

      font-feature-settings: 'pnum' on, 'lnum' on

      color: $black

      transition: color 200ms

    &:hover
      .label
        color: $primary
        transition: color 100ms

      svg path
        fill: $black
        transition: fill 100ms

  .label
    max-width: 8rem
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  .count
    font-weight: normal
