@import './constants'

.PlanImage
  position: relative
  cursor: grab

  .error
    display: flex

    svg
      width: 1.25rem
      height: 1.25rem
      margin-top: 1px

    svg path
      fill: $danger

  &.hidden
    display: none

  img
    -khtml-user-select: none
    -o-user-select: none
    -moz-user-select: none
    -webkit-user-select: none
    user-select: none

    // This is required for Safari, otherwise the desk circle becomes blurry upon zoom.
    transform: translateZ(-1px)

  &.showCrosshair img
    cursor: crosshair
