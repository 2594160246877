@import '../../../components/constants'

.VisitorInviteCancelForm
    width: 42em

    .additiona-info-input
        textarea
            min-height: 150px

    .subtext
        display: flex
        align-items: center
        color: $gray-4
        font-size: 14px
        svg
            margin-right: 1em
