@import ../constants

.DepartmentPicker
  background: var(--background-mobile)
  overflow-y: scroll

  .picker-content
    padding: 0 1rem

    h2
      font-size: $font-size-title

    .department-item
      cursor: pointer
      display: flex
      align-items: flex-start
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 100%
      background: white
      padding: 1rem
      margin-bottom: 1rem

      .department-info
        flex-grow: 1

      .name
        font-size: large

      .users-count
        display: flex
        align-items: center
        color: $primary
        margin-left: 1rem
        padding: 0 0.5rem

        svg
          width: 1rem
          height: 1rem
          margin-left: 0.25rem

        path
          fill: $primary
