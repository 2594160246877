@import ../components/constants

.UpdateNotification
    z-index: 10
    width: 20rem
    display: flex
    flex-direction: column
    position: fixed
    align-items: flex-start
    bottom: 3rem
    right: 3rem
    padding: 0.875rem 1rem
    background-color: $white
    box-shadow: 0 0 0.5rem rgba($black, 0.25)

    .TitleRow
        display: flex
        justify-content: space-between
        width: 100%

        p.UpdateTitle
            margin: 0
            color: $black
            font-size: 1rem
            font-weight: 700

    .UpdateDescription
        font-size: 0.75rem
        font-weight: 400
        color: $gray-8

    .Button.RefreshButton
        background-color: transparent
        color: $primary
        padding: 0
        gap: 0.75rem
        text-transform: none

        .label
            display: flex
            align-items: center
            padding: 0
            gap: 0.75rem
            letter-spacing: 0

            svg
                g
                path
                    fill: $primary
