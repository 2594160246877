
@import '../components/constants.sass'

.SortableCard
  background-color: $white
  padding: 35px 25px
  justify-content: space-between
  align-items: center
  width: 100%
  display: flex

  &.disabled
    opacity: .5
    &:hover
      cursor: not-allowed

    & .Actions
      opacity: .2

  .Content
    width: 100%

  .Actions
    display: flex
    gap: 2rem

    .DragHandle
      cursor: grab

    .Delete
      cursor: pointer
