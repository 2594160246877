@import ../../../components/constants

.ManageInvites
  max-width: 58rem

  .loading
    align-self: center
    .Loader
      background: transparent
      box-shadow: none

  .Heading
    margin-top: 0.5rem
    display: flex
    align-items: center
    width: 100%
    gap: 1rem

    .DayNav
      margin-left: 0

    .date-range
      font-weight: 500

  .NameCell
    display: flex
    align-items: center

    .number
      width: 20px
      height: 20px
      text-align: center
      font-weight: bold
      color: $gray-3
      margin-right: 0.5rem

    .invite
      .name
        font-weight: bold

      .host
        font-weight: bold
        white-space: nowrap
        font-size: 10px
        color: $gray-3

  .StatusCell
    svg
      vertical-align: middle
      height: 1.2rem
      margin-right: 0.25rem
      path
        fill: black

  .FooterCell
    font-size: 12px
    color: $gray-3
    b
      font-size: 14px
    span
      vertical-align: bottom
    svg
      vertical-align: middle
      height: 1rem
      margin-right: 0.25rem

      path
        fill: $gray-3
