@import '../../../components/constants.sass'

.VisitorSettings
    .Intro
        max-width: 60%

    .loading
        align-self: center

    .subtext
        color: $gray-4
        display: flex
        align-items: center
        margin-top: 1em
        font-size: 14px
        svg
            margin-right: 1rem
            fill: $gray-4
            width: 20px
            height: 20px
