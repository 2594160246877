@import './constants.sass'
.asset-icon-picker.Field
  position: relative

  .icon-picker
    padding: 1rem
    position: absolute
    background-color: $white
    border-radius: 3px
    z-index: 10
    top: 0
    left: 0
    width: 26rem
    height: 30rem
    transform-origin: 2rem 2rem

    +shadow-1

    &.enter
      opacity: 0
      transform: scale(.9)

    &.enter-active
      opacity: 1
      transform: scale(1)
      transition: opacity 300ms, transform 300ms

    &.exit
      opacity: 1

    &.exit-active
      opacity: 0
      transform: scale(.9)
      transition: opacity 300ms, transform 300ms

    .Filters
      padding-right: 1rem
      padding-bottom: 1rem
      border-bottom: 1px solid $gray-2
      .Search
        width: 100%

    .icon-list
      margin-top: 1rem
      height: calc(100% - 4rem)
      width: 25rem
      display: flex
      flex-wrap: wrap
      column-gap: 1rem
      row-gap: 1rem
      overflow: auto
      align-content: flex-start

      button
        +button-unset
        height: 2rem
        width: 2rem
        svg
          path
            fill: $gray-4
            transition: fill 200ms
        &:hover
          color: red
          svg
            path
              transition: fill 200ms
              fill: $black
