@import './../../constants.sass'

.PairDeviceForm
  width: 34rem

  .switch
    width: 100%
    display: flex
    justify-content: center
    margin-bottom: 2rem

  .info
    width: 100%
    color: $gray-4
    margin-bottom: 3rem

  .fields
    .input-info
      display: flex
      flex-direction: column
      width: 100%
      margin-bottom: 2rem

      .text
        color: $gray-4

  .FieldWrapper.Uppercase
    input
      text-transform: uppercase
