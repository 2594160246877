@import ../../components/constants

.WeekendToggle
  font-size: 0.875rem
  font-weight: bold
  text-transform: lowercase
  font-variant: small-caps
  color: $gray-3
  cursor: pointer
  transition: color 300ms

  &:hover
    color: black