@import ../constants

.VisitorHeader
  margin-top: 5rem

  @media (max-width: 480px)
    margin-top: 1rem

  .navigation
    width: 21rem
    display: flex

    .arrow
      width: 24px
      cursor: pointer
      display: flex
      justify-content: center

      svg path
        fill: black

    .title
      flex-grow: 1
      text-align: center
      font-weight: bold

    .close
      width: 24px
      text-align: right
      display: flex
      justify-content: center

      svg
        path
          fill: black

  .logo
    +flex-center
    min-height: 11rem
    max-height: 11rem

    img
      max-height: 11rem
      width: 100%
      max-width: 11rem

      @media (max-width: 480px)
        max-height: 9rem
        width: 100%
        max-width: 9rem
