.Filters
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-start

  .Dropdown, .DropdownMulti
    margin-right: 0.5rem

  .Button
    margin-left: 0.5rem

  .Search
    margin-right: 0

  .building-filter, .floor-filter, .department-filter, .desk-filter, .role-filter, .people-filter, .amenity-filter, .asset-status-filter, .bulk-action-filter, .visitors-filter
    .control
      transition: width 400ms
