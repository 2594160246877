@import '../../../../components/constants'
@import '../../../../utils'

.RoomsOverviewPage
  .Intro
    line-height: toRem(24)
    max-width: toRem(563)

  .RoomsOverviewLayout
    display: flex
    align-items: flex-start

    .RoomSettingsForm, .RoomSettingsDetails
      width: toRem(563)

      .Card
        min-width: auto

    .RoomsOverviewSubscriptions
      display: flex
      flex-direction: column
      margin-left: toRem(16)
      width: toRem(232)
      gap: toRem(16)
