@import ../../components/constants

.Manage

	.date-range
		pointer-events: none
		user-select: none

	.WeekNav, .DayNav
		margin-right: 0.5rem
		margin-left: 0.5rem

	.divider
		margin-left: 0.5rem
		margin-right: 0.5rem

	.time-picker
		color: $primary

		.react-time-picker__wrapper
			border: none

		input, select
			color: $primary

	.Loader
		margin-top: 20%
