@import ../../constants

.TimeRangePicker
  width: 100%
  display: flex
  align-items: center
  position: relative

  span
    margin-left: 1rem
    margin-right: 1rem

  .error-text
    position: absolute
    bottom: -1.5rem
