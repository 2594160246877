@import ../../constants

.ImageSelector
  width: 100%

  .image-display
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 160px
    height: 160px
    border: 1px solid $gray-3
    color: $gray-3
    cursor: pointer

    .remove
      position: absolute
      top: 0
      right: 0
      margin: .5rem .5rem 0 0
      backdrop-filter: blur(1px)
      background: rgba(255, 255, 255, .6)

      > svg path
        fill: $primary

      &:hover > svg path
        fill: $black

    .image-placeholder
      height: 100%

    .image
      max-height: 140px
      width: 100%
      max-width: 140px
      object-fit: cover

  input
    display: none
