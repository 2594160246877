@import ../constants

.BottomNav
  display: flex
  justify-content: space-around
  background: white
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15)
  padding-bottom: var(--view-padding-bottom-safe)

  a
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    flex-basis: 20%
    display: flex
    justify-content: center
    text-decoration: none !important
    padding: 0.5rem 0
    border-radius: 50%

.NavButton
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  font-size: $font-size-small
  color: $black

  & svg path
    fill: $black

  &.isActive
    color: $primary

    & svg path
      fill: $primary
