@import '../components/constants'

.LogoutLink
  color: $primary

.NotPaid
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: stretch
  height: 100%

  padding-bottom: 0 !important

  .Screen
    flex: 1

  .NotPaidNotice
    flex: 1
    max-width: 35rem
    display: flex
    align-self: center
    justify-content: center !important

    a
      display: inline !important
      font-weight: bold !important

.NotPaidMobile
  text-align: center
  padding: 2rem

  .LockImage
    margin-bottom: 2rem

  .SmallScreen
    margin-bottom: 1rem
    display: block

  .LogoutLink
    color: $primary
    margin: 0 auto

  .BottomNav
    position: fixed
    bottom: 0
    left: 0
    width: 100%
