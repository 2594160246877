@import '../../../../components/constants.sass'

.DemoDataModal.ModalForm
  width: 30rem

  .top-bar
    border-bottom: 1px solid $gray-2

    .close
      +button-unset

  .hint
    font-size: 16px
    color: $black
    font-weight: 400
    padding-top: 2rem
    text-align: left
