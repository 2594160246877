@import ../../../../components/constants
.suggest-asset-types
  .book-asset
    margin-bottom: .5rem
    text-align: center
    @media screen and (min-height: 700px)
      margin-bottom: 1.5rem

  .suggest-asset-types-list
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr))
    justify-content: space-around
    margin-bottom: 1rem
    &.one
      grid-template-columns: 1fr

    .item
      padding: .75rem
      border: 1px solid $black
      display: flex
      align-items: center
      flex-direction: column
      background-color: $white
      overflow: hidden

      &:nth-child(even)
        border-left: none
      &:nth-child(n + 3)
        border-top: none
      &:first-child
        border: 1px solid $black
      &:last-child
        border-left: 1px solid $black
      &:nth-child(2n)
        border-left: none

      .name
        margin-top: .5rem
        overflow: hidden
        text-overflow: ellipsis
        max-width: 100%

      svg.more-icon path
        fill: $black
