@import "../../../../components/constants"

.TimeSlots
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

	.Filters
		width: 36rem

.Card.bookable-slots
	min-width: 36rem
	padding: 0

	.slot-padding
		padding: 1rem

	p:first-child
		margin-top: 0.5rem
	p:last-child
		margin-bottom: 0.5rem

	.pagination
		padding: 32px 16px

	p
		display: flex
		align-items: center

		.timerange
			padding-left: 1rem
			width: auto

		.name
			flex-grow: 1
			min-width: 10rem
			padding-left: 1rem
			padding-right: 1rem

	.timeslot-pagination
		justify-content: space-between

		.summary
			color: $gray-4
			font-size: 12px
