@import ../../../components/constants

.PeopleSchedule
  .Heading
    margin-top: 0.5rem
    align-items: center
    width: 100%

    .spacer
      flex-grow: 1

    .WeekNav
      margin-left: 0

    .date-range
      font-weight: 500

    .WeekendToggle
      margin-left: 1rem

  .Filters
    width: 100%

    .Search
      width: 19rem

  .RowHeading
    .user
      margin-right: 0.5rem

    .you
      color: $gray-3

  .Button
    +shadow-1
    margin-left: 1rem

  .Loader
    +shadow-1
    background: $white
    width: 100%
    display: flex
    height: 8rem
    justify-content: center
    align-items: center
