.PaymentMethodInfo
  div:not(:last-of-type)
    margin-bottom: .75rem

  div.PaymentMethodInfo__header
    margin-bottom: 1.5rem
    font-weight: 700

  button
    margin-top: 1rem
