@import ../constants

.DatePicker
  .scroll-vertical::-webkit-scrollbar
    display: none

  .scroll-vertical
    display: flex
    overflow-x: scroll
    scrollbar-width: none
    flex-basis: 100%

    .scroll-item
      cursor: pointer
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      border: 1px solid $gray-2
      margin-right: 0.5rem
      padding: 0.5rem 1rem
      background-color: $white

      .date
        height: 100%
        display: flex
        text-align: right
        cursor: pointer
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none
        position: relative
        justify-content: center
        align-items: center

        svg path
          fill: black

        .date-input
          position: absolute
          -webkit-appearance: none
          border-radius: 0
          width: 100%
          height: 100%
          top: 0
          left: 0
          opacity: 0

      .slot-name
        font-weight: bold
        text-align: center
        white-space: nowrap
        min-width: 4rem

      .slot-time
        text-align: center
        white-space: nowrap
        font-size: $font-size-small
        color: $gray-5

    .active
      border: 1px solid $primary
      color: $primary
      background: $blue-text-background

      .slot-time
        color: $primary

      .date svg
        margin-right: 0.5rem

      .date svg path
        fill: $primary
