@import ../../../components/constants

.DeviceDetail
  .loading
    align-self: center

  .device-detail-content
    width: 100%
    display: flex

  .info-panel
    margin-left: 3rem

    .infos
      display: flex
      align-items: flex-start
      align-content: flex-start
      flex-wrap: wrap
      gap: 1rem
      width: 18rem
      padding-bottom: 1rem
      border-bottom: 1px solid $gray-2

      .info
        width: 40%

      .info:first-child, .info:last-child
        width: 100%

      .info-name
        color: $gray-4
        font-weight: 700
        margin-bottom: 0.75rem

    .actions
      display: flex
      gap: 1rem
      padding-top: 1rem
