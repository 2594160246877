@import ../../../components/constants

.RouteView.OrganizationDetails.isConstrained
  width: 32rem

  .View
    align-items: center

    .Heading
      margin-bottom: 1rem

    .Intro
      text-align: center
      margin-bottom: 2rem

    .PageForm
      .Card
        min-width: 30rem

        .google-map
          height: 12.5rem
          width: 100%
          background: $gray-3
          margin-bottom: 2rem

      .PageActions
        justify-content: space-between

        .spacer
          display: none

    .OrganizationDetails__label-left
      display: flex
      flex-direction: row-reverse

      .ImageSelector, .ColorPicker
        width: auto
        margin-right: 2rem

      .Label
        margin-bottom: 0

        p
          margin-top: 0

        .text--small
          text-weight: 400

        .guidelines
          font-size: .75rem
          font-weight: 400
          color: $gray-4

    .label-left
      display: block

      .Label
        float: right
        width: 14rem
        font-size: .75rem
        font-weight: 400
        color: $gray-4

      .ColorPicker
        width: 10.225rem
        margin-right: 2rem

.LocationPin
  position: absolute
  left: -20px
  top: -40px

  svg
    height: 40px
    width: 40px
