@import ../../../components/constants

.BillingSection
	width: 100%
	margin-top: 3.5rem

	h2
		color: $gray-4
		font-size: 1rem
		text-transform: uppercase
		margin-top: 0
		margin-bottom: 1rem

	.Intro
		width: 35rem
		margin-bottom: 2rem
