@import ../../constants

.Card:not(.old-card)
  border-radius: 2px
  background-color: $white
  overflow: hidden
  display: flex
  flex-direction: column

  .card-header
    background-color: $gray-7
    padding: 1.5rem 2rem
    font-variant-numeric: oldstyle-nums proportional-nums
    font-size: 1rem
    font-style: normal
    font-weight: 700
    line-height: 150%

  .card-content
    padding: 2rem 2rem 3rem
    flex-direction: column
    display: flex
    flex-grow: 1

    .card-content-body
      font-size: 0.875rem
      font-style: normal
      font-weight: 400
      line-height: 1.5rem
      flex-grow: 1
  .card-actions
    padding-top: 2rem
    flex-grow: 0
