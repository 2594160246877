.PrepaidCodeCard
  .Card
    width: 24.875rem

    .FieldWrapper
      margin-bottom: 1rem
      width: 100%

    .card-actions
      padding-top: .5rem
