@import ../constants
@import ../../utils

.HealthEditQuestions
  width: 100%

  .Label

    .description
      margin-top: 0.5rem
      font-weight: normal

  .Questions
    margin-top: 2rem

    .Card.SortableCard
      box-shadow: none

    .description
      margin-bottom: 2rem

    .Question
      max-width: 95%

  .Action
    margin: 1.5rem 0

.HealthQuestion
  margin-bottom: toRem(26)

  .Button
    min-width: toRem(120)
    .label
      text-align: center
      width: 100%

  .Remove
    height: 24px
    text-align: right

    svg
      cursor: pointer

  .Question
    display: flex
    margin-bottom: 1rem

    .number
      display: flex
      padding-top: 0.5rem

  .Answers
    display: flex

    .Answer
      width: 8rem
      margin-left: 1rem
      margin-right: 1rem
      padding: 0.25rem
      background-color: $white
      text-align: center
      cursor: pointer

      &.isSelected
        border: 1px solid $primary
        background-color: $blue-text-background
        font-weight: bold
