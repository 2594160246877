.RouteView.Error
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center

  padding: .5rem
  
  // & > *
  //   max-width: 42rem

  code
    background-color: white

  p
    margin-bottom: 0
    font-size: 14px