@import '../constants.sass'

.ModalDeleteForm
  position: relative
  width: 28rem

  .title
    border-bottom: 1px solid $gray-2
    margin-bottom: 2rem

    h1
      font-style: normal
      font-weight: bold
      font-size: 18px
      line-height: 25px

      text-align: center
      font-feature-settings: 'pnum' on, 'onum' on

      color: $black

      margin:
        top: -0.5rem
        bottom: 1.5rem

  .close
    cursor: pointer
    position: absolute
    top: 0.125rem
    right: 0

    svg path
      transition: fill 200ms

    &:hover
      svg path
        fill: $black

  .fields
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .actions
    display: flex
    justify-content: space-between
    margin-top: 1.5rem
    border-top: 1px solid $gray-2
    padding-top: 1.5rem

    &.actions-new, &.actions-download, &.actions-delete-only
      justify-content: flex-end

    .actions-edit
      input[type='submit']
        order: 2
