@import ../../../../components/constants

.mobile-picker-item
  cursor: pointer
  display: flex
  align-items: flex-start
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  width: 100%
  background: white
  padding: 1rem
  margin-bottom: 1rem

  .mobile-picker-item-info
    flex-grow: 1

    .mobile-picker-item-info-name
      font-size: large

    .mobile-picker-item-info-address
      color: gray

  &.disabled
    cursor: auto
    opacity: 0.5
