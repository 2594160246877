@import ../../../components/constants

.Edit
  display: flex
  flex-direction: column

  .body
    flex-grow: 1
    padding: 0 1rem

    form
      border: 1px solid black
      background: white
      padding: 0.8rem

      .input-field
        margin-bottom: 1.5rem

        input
          width: 100%
          padding: 0.5rem 0
          border: none
          border-bottom: 2px solid black
          outline: none
          margin-bottom: 0.5rem
          font-size: $font-size-normal
          -webkit-appearance: none
          border-radius: 0

        .input-error
          color: $danger
          text-align: left
          font-weight: bold
          font-size: $font-size-small

      .input-field:last-child
        margin-bottom: 0

  .action
    padding: 1rem 1rem calc(1rem + var(--view-padding-bottom-safe)) 1rem
