@import ../constants

.LaunchBar
  padding: 2rem 1rem 1rem 1rem
  background: linear-gradient(to bottom, white, white 70%, var(--background-mobile) 71%, var(--background-mobile))

  .lead
    font-weight: 100
    font-size: 14pt
    text-align: center
    margin-bottom: 1.5rem

  .actions
    display: flex

    a
      width: 50%
      flex-grow: 1
      color: black

      &:nth-child(n + 2)
        .LaunchButton
          border-left: none

.LaunchButton
  display: flex
  font-size: $font-size-normal
  flex-direction: column
  justify-content: center
  align-items: center
  border: 1px solid black
  padding: 1rem 0
  background: white
  height: 100%
  text-align: center

  svg
    margin-bottom: 0.25rem

    path
      fill: black
