@import ../../constants
@import ../../../utils

.SliderTimePicker
  color: $white
  height: toRem(56)
  background: $black

  .SliderTimePickerOption
    width: auto
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    .SliderTimePickerSlot
      position: relative
      height: 100%
      display: flex
      justify-content: center
      align-items: flex-end
      font-size: $font-size-small
      padding: 12pt 6pt 0 6pt

      &.SliderTimePickerSlotSimple
        .SliderTimePickerSlotName
          display: none

        .SliderTimePickerSlotMarker
          height: toRem(16)
          opacity: .5

      &.SliderTimePickerSlotSimple.active
        .SliderTimePickerSlotMarker
          height: toRem(22)

      &.active
        font-weight: bold
        font-size: $font-size-normal
        text-shadow: 0 0 toRem(10)

        .SliderTimePickerSlotName
          padding-bottom: toRem(44)

        .SliderTimePickerSlotMarker
          opacity: 1

      .SliderTimePickerSlotName
        position: absolute
        text-align: center
        height: toRem(22)
        width: toRem(72)
        padding-bottom: toRem(40)

      .SliderTimePickerSlotMarker
        text-align: center
        vertical-align: text-bottom
