@import ../../constants

=arrow
  border-color: $primary
  border-width: 2px 2px 0 0
  height: 7px
  width: 7px

.DatePickerInput
  +inner-shadow-2
  width: 100%
  min-width: 160px
  display: flex
  align-items: center
  background-color: $gray-1
  position: relative

  .react-datepicker-wrapper
    width: 100%

  .calendar-icon
    position: absolute
    right: 0.75rem
    pointer-events: none
    path
      fill: $primary

  &.disabled
    .calendar-icon
      path
        fill: $gray-3

  &.error
    +error-border

  .react-datepicker__input-container
    background-color: $gray-1
    border: 0
    padding: 8px
    color: $black
    width: 100%
    height: 3rem

    .date-picker-input
      border: none
      background-color: $gray-1
      font-size: 1rem
      width: 100%
      font-feature-settings: 'tnum' on, 'onum' on
      height: 32px

      &:focus-visible
        border: 0
        outline: 0

    .react-datepicker__close-icon
      position: absolute
      right: 2.5rem

      &::after
        background-color: transparent
        color: $gray-3
        padding: 0
        font-size: 24px
        font-weight: bold

      &:hover
        &::after
          color: $gray-4

#WeekPickerPortal,
#DatePickerPortal
  position: fixed
  z-index: 201

  .react-datepicker-popper
    .react-datepicker
      +font-family
      +shadow-1
      font-size: .875rem
      padding: 1rem
      border: none
      border-radius: 3px

      .react-datepicker__navigation-icon
        &::before
          +arrow

      .react-datepicker__navigation--previous
        left: 11.5rem
        top: 1rem

      .react-datepicker__navigation--next
        right: 1rem
        top: 1rem

      .react-datepicker__header
        background-color: $white
        padding-top: 0
        border: none
        text-align: left

        .react-datepicker__current-month
          padding-bottom: 1rem
          margin-left: 1rem
          margin-right: 1rem
          font-size: 1rem

        .react-datepicker__month-dropdown-container
          margin-right: 0
          margin-left: 0

          .react-datepicker__month-read-view--down-arrow
            right: -1rem
            top: 4px
            +arrow

        .react-datepicker__year-dropdown-container
          .react-datepicker__year-read-view--down-arrow
            right: -1rem
            top: 4px
            +arrow

        .react-datepicker__day-names
          text-align: center
          .react-datepicker__day-name
            color: $gray-4
            font-weight: 700

      .react-datepicker__header__dropdown
        position: relative
        margin-left: 1rem
        margin-right: 1rem
        margin-bottom: .75rem
        padding-bottom: 1rem
        display: flex
        justify-content: space-between
        border-bottom: 1px solid $gray-2

        &:empty
          padding-bottom: 0

        .react-datepicker__year-dropdown
          right: 0
          left: auto

        .react-datepicker__month-dropdown
          left: 0

        .react-datepicker__year-dropdown-container
          margin-left: auto

        .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container
          .react-datepicker__month-dropdown, .react-datepicker__year-dropdown
            +shadow-1
            background-color: $white
            border: 0
            border-radius: 3px
            padding: 1rem

            .react-datepicker__month-option, .react-datepicker__year-option
              text-align: left
              padding: .25rem
              color: $gray-4

              &:hover
                background-color: transparent
                color: $primary

              &.react-datepicker__month-option--selected_month, &.react-datepicker__year-option--selected_year
                color: $black

              .react-datepicker__month-option--selected, .react-datepicker__year-option--selected
                display: none
            .react-datepicker__navigation--years-upcoming,.react-datepicker__navigation--years-previous
              &::after
                content: ''
                display: block
                position: absolute
                border: solid
                +arrow
                transform: rotate(-45deg)
                height: 10px
                width: 10px
                top: 14px
                left: 11px

            .react-datepicker__navigation--years-previous::after
              top: 11px
              transform: rotate(135deg)

      .react-datepicker__day
        color: $gray-4
        font-feature-settings: 'tnum' on, 'onum' on

        &:focus-visible
          border: 0
          outline: 0

        &--weekend
          color: $gray-3

        &--keyboard-selected
          background-color: $gray-2
          border-radius: 50%

        &--today
          color: $black

        &--selected
          color: $white
          background-color: $primary
          border-radius: 50%

        &--outside-month
          color: $gray-3

        &--disabled
          color: $gray-2

        &:hover
          border-radius: 50%

    .react-datepicker__today-button
      color: $primary
      border: 0
      background-color: transparent
      font-weight: 400
      text-align: start
      margin-left: 1rem

      .TodayButton
        display: flex
        align-items: center
        gap: 1rem

        >svg path
          fill: $primary

      &:hover
        color: $primary-darker

        .TodayButton
          >svg path
            fill: $primary-darker
