@import ../../components/constants
@import ../../utils

.empty-panel
	width: calc(100% - 42.7rem)

.floor
	display: flex
	width: 100%
	align-items: flex-start
	justify-content: space-between

	.Filters
		.Dropdown
			.control
				box-shadow: none
				border: none

	&-wrapper
		display: flex
		flex-direction: column
		width: 100%

	.Map
		pointer-events: none
		cursor: pointer

	.floor-plan
		height: calc(var(--view-height-safe) - 23.55rem)
		padding: 0
		display: flex
		justify-content: center
		align-items: stretch
		width: 100%
		box-shadow: none

		& > *
			width: 100%

		&.floor-plan-missing, &.floor-missing, &.loading
			min-height: toRem(348)
			height: auto
			display: flex
			justify-content: center
			align-items: center
			text-align: center

		&.floor-plan-missing
			font-size: toRem(14)
			color: $gray-4

			svg
				width: toRem(48)
				height: toRem(48)
				margin-bottom: toRem(12)

				path
					fill: $gray-3

		&.building-missing, &.floor-missing, & + div
			text-align: center
			min-height: auto
			height: auto
			color: $gray-4
			padding: toRem(48) toRem(64)
			background: #FEFFDE

	.panel
		margin-top: toRem(24)
		background: $white
		margin-left: toRem(16)
		border-radius: toRem(2)
		width: calc(100% - 40rem)
		padding: toRem(32) toRem(32) toRem(48)

		&-head
			margin-bottom: toRem(12)

			&-title
				color: $gray-4
				margin: 0
				font-weight: 700
				font-size: toRem(14)
				line-height: toRem(24)

		&-body
			.Button.link
				.label
					font-weight: 500
					font-size: toRem(14)

			.options
				.option
					&:not(:last-child)
						margin-bottom: toRem(12)

					.Loader
						text-align: left

					.helper-info
						color: $gray-4
						margin-top: toRem(12)
						font-size: toRem(14)

					&.info
						display: flex
						align-items: center

						svg.InfoIcon
							min-width: toRem(24)
							width: toRem(24)
							height: toRem(24)
							margin-right: toRem(8)

							path
								fill: $gray-4

						span
							font-size: toRem(14)
							line-height: toRem(24)
							font-weight: 500
							color: $gray-4

		&-foot
			margin-top: toRem(32)

			.floor-plan-cta
				font-size: toRem(12)

		&.hidden
			visibility: hidden
