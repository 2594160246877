@import ./../../../components/constants

@keyframes expandY
  0%
    transform: scaleY(0)

  100%
    transform: scaleY(1)

.Alert
  font-size: 13px
  padding: 1.7rem
  animation: 0.2s expandY
  width: inherit
  text-align: center
  position: relative

  &.warning
    background-color: $warning-2
    & .CloseButton
      path
        fill: $warning-2

  &.info
    background-color: $info
    & .CloseButton
      path
        fill: $info

  &.error
    background-color: $error
    & .CloseButton
      path
        fill: $error

  &.danger
    background-color: $danger
    & .CloseButton
      path
        fill: $danger

  &.isLink
    cursor: pointer

  &.isDisabled
    opacity: .5
    pointer-events: none

  .CloseButton
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer
    background: none
    border: none
    font-size: 18px
    color: #555
    opacity: 0.7
    transition: opacity 0.2s
    & path
      filter: brightness(50%)

    &:hover
      opacity: 1
