@import ../constants

.TextInput
  width: 100%
  position: relative

  svg
    position: absolute
    right: 0.75rem
    top: 50%
    transform: translateY(-50%)

  input
    width: 100%
    background: $gray-1
    +inner-shadow-2
    border: 0

    padding: 8px 12px

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

      svg path
        fill: $black

  input[type=text], input[type=email]
    cursor: text

  input:focus
    outline: none

.isDisabled
  input[disabled]
    color: $gray-4

  svg.lock path
    fill: $gray-4

.isDisabled:hover
  svg circle
    fill: mix($gray-1, $gray-2)

  svg.lock path
    fill: $black
