@import '../constants'

@keyframes gradient-wipe
  0%
    background-position: 0vw 0
  100%
    background-position: 200vw 0

.Person, .Reservation
  --user-color: $gray-3
  display: flex
  flex-direction: column
  font-style: normal
  font-size: 12px
  line-height: 16px
  margin: 3px 0
  padding-right: 6px
  text-transform: capitalize
  color: $black
  cursor: pointer
  transition: color 200ms

  &:hover
    color: $primary
    transition: color 100ms

  .label
    display: flex

    .name
      overflow: hidden
      text-overflow: ellipsis
      flex-basis: calc(100% - 1.75rem)
      margin-left: 0.5rem

  .CheckinLabel
    margin:
      top: 0.25rem
      left: 1.25rem

  svg
    flex-basis: 1rem

    circle, rect
      fill: var(--user-color)
      &.inner
        opacity: 0

  span.you
    font-weight: normal
    margin-left: 0.5rem
    color: $gray-3

  &.isPartial
    svg
      circle, rect
        &.inner
          fill: white
          opacity: 1

.ReservationAvailable
  +button-unset
  cursor: pointer
  padding-left: 1.25rem
  margin: 0 0 3px 0
  color: $gray-3
  transition: color 200ms
  font-size: 0.75rem
  height: 1rem

.ReservationAvailable:hover
  color: $primary
  transition: color 50ms

.ReservationUnavailable
  padding-left: 1.25rem
  margin: 3px 0
  color: $gray-3
  font-style: italic

.Desk
  display: flex
  align-items: center
  font-size: 12px
  line-height: 16px
  padding: 6px 0

  .row-number
    margin-left: 1rem

.ReservationTable
  +shadow-1
  position: relative
  background: $white
  border-radius: 3px
  width: 100%

  & table:before
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 3px
    background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
    top: 48px
    z-index: 2
    opacity: 0
    transition: opacity 200ms
    pointer-events: none

  &.isLoading
    table:before
      animation: gradient-wipe 1600ms linear infinite
      opacity: 1
      transition: opacity 800ms

  table
    width: 100%
    border-spacing: 0
    table-layout: fixed

    th, td
      width: calc(100% / 8)

    tr:first-child th:first-child
      border-top-left-radius: 3px

    tr:first-child th:last-child
      border-top-right-radius: 3px

    tr:last-child td:first-child
      border-bottom-left-radius: 3px

    tr:last-child td:last-child
      border-bottom-right-radius: 3px

  thead
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 19px
    color: $gray-3
    text-align: left

    th
      position: sticky
      top: 0

      background: $white
      padding-left: 1.25rem
      height: 3rem
      border-bottom: 1px solid $gray-2

      .Day.today .day
        color: $black

      .Day
        display: flex
        align-items: center
        justify-content: flex-start
        font-weight: 500

        span
          margin-right: 0.5rem

  tbody, tfoot
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 16px
    font-feature-settings: 'tnum' on, 'onum' on

    tr.first-row, tr.error-no-results
      td
        border-top: 0.5rem solid $white

    tr.last-row
      td
        padding-bottom: 1rem
        border-bottom: none

    tr.error td
      font-size: 14px
      line-height: 24px
      text-align: center
      padding: 2rem

    td
      min-height: 1.5rem
      border-bottom: 1px solid mix($gray-1, $gray-2)
      padding:
        top: 6px
        bottom: 6px

    .row-number
      font-weight: bold
      margin-right: 0.5rem
      font-feature-settings: 'tnum' on, 'onum' on
      color: $gray-3

  tfoot
    td
      position: sticky
      bottom: 0
      background: $white
      padding-left: 0.3rem
      height: calc(1.5rem + 2 * 6px + 1px + 0.75rem)
      border:
        top: 1px solid mix($gray-1, $gray-2)
        bottom: 0.75rem solid $white

      .attendees
        display: flex
        justify-content: flex-start
        align-items: center
        color: $gray-3

      .attendees .none
        margin-left: 1.25rem

    .pagination-row
      td
        border-bottom: none
        padding: 0.5rem 0
        column-span: 8

    svg
      margin-right: 0.7rem

      path
        fill: mix($gray-2, $gray-3)

  &.show-pagination
    tfoot
      tr.summary-row
        td
          bottom: calc(3rem + 1px)

.RowHeading
  display: flex
  align-items: center
  font-size: 12px
  line-height: 16px
  padding: 6px 6px 6px 0px

  .row-number
    margin-left: 1rem

  .user, .desk, .room, .asset, .people
    overflow: hidden
    text-overflow: ellipsis
