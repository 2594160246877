@import './components/constants.sass'
@import './constants.sass'

.ReactModal__Content.ReactModal__Content--after-open
  max-height: 90vh

a, a:link, a:active, a:visited
  color: $primary
  text-decoration: none

a:hover
  color: black
  text-decoration: underline

html
  background: var(--background-mobile)
  padding-left: 0
  padding-left: const(safe-area-inset-left)
  padding-left: env(safe-area-inset-left)
  padding-right: 0
  padding-right: const(safe-area-inset-right)
  padding-right: env(safe-area-inset-right)
  -webkit-tap-highlight-color: transparent
  -webkit-touch-callout: none

  overflow-x: hidden

.Toastify__toast
  padding: 0.5rem 0.5rem 0.5rem 1.5rem
  margin-top: var(--view-padding-top-safe)

.Toastify__toast--info
  background: $gray-4
  color: white

.Toastify__toast-body
  white-space: pre-line

.App
  +flex-center
  flex-direction: column
  align-items: stretch
  min-height: var(--view-height-safe)
  margin-right: calc(-1 * (100vw - 100%))

  .RouteView
    width: 100%
    flex-grow: 1
    padding-bottom: 3rem

    margin:
      left: auto
      right: auto

    &.Manage:not(.isConstrained)
      padding:
        left: 1rem
        right: 1rem

    &.Settings, &.isConstrained
      max-width: 73rem

    .View
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
      width: 100%

.ModalForm
  position: relative

  &.isConstrained
    max-width: 30rem

  .title
    border-bottom: 1px solid $gray-2
    margin-bottom: 2rem

  .close
    cursor: pointer
    position: absolute
    top: 0.125rem
    right: 0

    svg path
      transition: fill 200ms

    &:hover
      svg path
        fill: $black

  .hint
    color: $gray-3
    margin-bottom: 2rem

  h1
    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 25px

    text-align: center
    font-feature-settings: 'pnum' on, 'onum' on

    color: $black

    margin:
      top: -0.5rem
      bottom: 1.5rem

  .fields
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-between

  .actions
    display: flex
    justify-content: space-between
    margin-top: 1.5rem
    border-top: 1px solid $gray-2
    padding-top: 1.5rem

    &.actions-new, &.actions-download, &.actions-delete-only
      justify-content: flex-end

    .actions-edit
      .Button.submit
        order: 2

    &.checkout-true
      justify-content: flex-end

    .Button.submit
      order: 2
