@import '../../../components/constants'
@import '../../../utils'

.card-body
  padding: 0 toRem(32)

.card-foot
  padding: toRem(32)

  border-radius: 0 0 toRem(2) toRem(2)

  &-pagination
    border-top: 1px solid $gray-1

    .Pagination
      justify-content: space-between

  &-no-assets
    background: #FEFFDE
    display: flex
    text-align: center
    flex-direction: column
    align-items: center

    div
      color: $gray-4
      line-height: toRem(24)

    .text-link
      color: $primary
      cursor: pointer
