.Login
  height: var(--view-height-safe)
  display: flex

  .Screen
    width: 100%
    height: 100%

    &, & .content
      justify-content: center
      align-items: center