
@import ./../../constants

.InputMulti
  display: flex
  flex-wrap: wrap
  width: 100%

  .InputValue
    padding: .7rem
    margin: .5em .5rem .5rem 0
    display: flex
    background-color: $gray-1
    font-size: 14px

    .Remove
      margin-left: .5rem
      display: flex
      justify-items: center
      cursor: pointer

      svg
        max-height: 20px
