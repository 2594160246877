@import "./../../components/constants"

.async-multiple-select
  &-container
    cursor: pointer
    width: 20rem
    cursor: pointer
    z-index: 10

  &__value-container
    padding: 2px 6px !important

  &__control
    padding-left: 2px
    min-height: 34px !important
    box-sizing: border-box

    border: 1px solid $gray-2 !important
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.12) !important

    border-radius: 16px !important

    font-style: normal
    font-size: 14px
    line-height: 19px

    font-feature-settings: 'pnum' on, 'lnum' on

    color: $black

    transition: color background-color 200ms

    &:hover, &:active
      border: 1px solid $gray-2

    &:focus, &--is-focused
      outline: none
      border-color: mix($gray-2, $gray-3) !important

  &__clear-indicator
    transition: color 100ms
    padding: 3px !important
    padding-right: 8px !important
    svg
      color: $primary

  &__multi-value
    border-radius: 16px !important
    cursor: pointer !important

    &__label
      padding: 1px

  &__no-options-message
    font-style: normal
    font-size: 14px !important
    line-height: 19px
    font-feature-settings: 'pnum' on, 'lnum' on
    color: $black
    transition: color 200ms

  &__option
    font-style: normal
    cursor: pointer !important
    font-weight: bold
    font-size: 14px !important
    line-height: 19px
    font-feature-settings: 'pnum' on, 'lnum' on
    color: $black
    transition: color 200ms
    border-radius: 15px
    background-color: white !important

    &--is-focused
      color: $primary !important

  &__menu
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06)
    border-radius: 15px !important

    &-notice--loading
      font-weight: normal
      font-size: 14px !important
      line-height: 19px