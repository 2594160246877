@import 'constants'

.license-indicator
  display: flex
  align-items: baseline
  font-size: .75rem
  color: $gray-4

  .indicator
    height: .5rem
    width: .5rem
    border-radius: .5rem
    margin-right: .5rem
    background-color: $valid

    &.inactive
      background-color: $danger
