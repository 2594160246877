@import ../../constants

.ConfirmationModal.ModalForm
    width: 28rem

    .top-bar
        border-bottom: 1px solid $gray-2

    .hint
        font-size: 16px
        color: $black
        font-weight: 400
        padding-bottom: 0.0625rem
        text-align: center
        padding-top: 2rem
