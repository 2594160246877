@import ../../../components/constants

.BuildingSelect
	width: 100%
	flex-grow: 1
	display: flex
	justify-content: center
	align-items: center

	.View
		display: flex
		max-width: 44em
		flex-direction: column
		align-items: flex-start

		.Card
			width: 100%

		.PageActions
			justify-content: space-between

			.spacer
				display: none
