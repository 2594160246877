@import ../constants

.ModalForm.ShareFloorForm
  width: 35rem

  .title
    margin-bottom: 1.5rem

  .fields
    display: block
    margin-bottom: .875rem
    font-size: 14px
    font-weight: 700
    line-height: 20px

  .floor-label
    color: $gray-4
    margin-bottom: .875rem

  .floor-pill
    display: inline-block
    padding: 0.25rem .625rem
    border: 1px solid $gray-2
    border-radius: 20px
    color: $black
    margin-bottom: .875rem

  .copy-to-clipboard
    margin-bottom: 1.5rem

  .MapContainer
    height: 283px
    width: 100%
    overflow: hidden
    margin-bottom: 1.25rem

    .PlanImage
      cursor: crosshair

    & .inverted
      img
        filter: invert(1)

  .activity-wrapper
    display: flex
    justify-content: space-between

    .access-activity-button
      font-size: 16px
      border: none
      display: block
      cursor: pointer
      background-color: transparent
      color: $gray-4
      margin-top: .2rem
      margin-bottom: 2rem

      &:hover
        color: $black

    .early-access
      color: $gray-4
      text-align: right
      display: flex
      flex-direction: column
      align-items: flex-end

      .early-access-title
        display: flex
        align-items: center
        margin-bottom: 0.5rem

        svg
          margin-right: .5rem
          width: 24px
          height: 24px

          path
            fill: $primary

      .early-access-info
        font-size: 10px
        margin-bottom: .5rem

  .mark
    font-size: 14px
    color: $gray-4
    margin-bottom: .75rem
