@import ../../components/constants

.Scan

  .scanWindow
    width: 32rem
    height: 32rem
    border-radius: 8px
    border: 1px solid $white
    z-index: 9999

  .scanInfo
    color: $white
    width: 31rem
    text-align: center
    z-index: 9999

  .link
    width: 100%
    z-index: 9999
