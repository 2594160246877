@import ../constants

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

.TopSelectors
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  height: 3.8rem
  padding: 1rem

  .location
    text-align: left
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    display: flex
    align-items: center

    svg path
      fill: black

  .department
    text-align: center
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    display: flex
    justify-content: center
    align-items: center

    .icon
      margin-right: 0.25rem

    svg path
      fill: black
    &.active
      color: $primary
      svg path
        fill: $primary

  .location, .department, .date
    max-width: calc((100vw - 2rem) / 3)

  .location, .department
    div
      overflow: hidden
      text-overflow: ellipsis
      white-space: pre
      max-width: calc(100% - 24px)

  .date
    position: relative
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    display: flex
    justify-content: flex-end
    align-items: center

    svg path
      fill: black

    .date-input
      position: absolute
      -webkit-appearance: none
      border-radius: 0
      width: 100%
      height: 100%
      top: 0
      left: 0
      opacity: 0

  .selector
    position: fixed
    top: var(--view-padding-top-safe)
    left: 0
    right: 0
    bottom: 0
    z-index: 1201
    animation: fadeIn 0.3s
