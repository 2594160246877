.PaymentDetails
  &__section
    margin-bottom: 3rem

  &__header
    margin-bottom: 1.5rem
    font-weight: 700

.MissingBillingDetails
  p
    margin-top: 0

  p:last-of-type
    margin-bottom: 2rem
