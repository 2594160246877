@import ../../../../components/constants

.building-picker
  background: var(--background-mobile)
  display: flex
  flex-direction: column

  h2
    padding: 0 1rem
    font-size: $font-size-title

  .picker-content
    flex-grow: 1
    padding: 0 1rem
    overflow-y: scroll
