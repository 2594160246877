@import ../components/constants

.failed-meetings-web
  .container
      max-height: 20rem
      overflow-y: auto
      margin: 1.5rem 1rem
      background-color: $gray-1

  .row
      padding: .5rem
      font-size: $font-size-small

.failed-meetings-mobile
  position: absolute
  bottom: 0
  left: 0

  width: 100%
  background-color: white

  padding: 1rem

  .container
    max-height: 15rem
    overflow-y: auto
    margin: 1rem
    background-color: $gray-1

  .row
    padding: .5rem
    font-size: $font-size-small