@import ../../components/constants
@import ../../utils

.VisitorBadge
  display: flex
  flex-direction: column
  justify-content: space-between
  width: toRem(378)
  min-height: toRem(632)
  padding: toRem(32)
  background-color: $white

  .VisitorBadgeBody
    display: flex
    align-items: center
    min-height: toRem(456)
    margin: 0 0 toRem(32)

    .VisitorBadgeContent
      .VisitorBadgeName
        font-size: toRem(38)
        line-height: toRem(52)
        font-weight: 700
        margin-bottom: toRem(16)

      .UserInfo
        margin-bottom: toRem(4)
        min-height: toRem(24)
        max-width: toRem(250)
        word-wrap: break-word
        font-size: toRem(20)
        line-height: toRem(24)

      .UserInfoSubtext
        font-size: toRem(18)
        line-height: toRem(24)
        margin-top: toRem(16)

  .VisitorBadgeFooter
    .VisitorBadgeBranding
      display: flex
      align-items: flex-end
      height: toRem(80)

      &.BrandingLogo
        width: toRem(140)

        img, svg
          max-height: 100%
          max-width: 100%
          object-fit: contain

      &.BrandingCompanyName
        h1
          font-size: toRem(20)
          line-height: toRem(24)
          margin: 0
