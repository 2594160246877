@import '../../constants'
.Stepper
  display: flex
  gap: 2rem
  font-size: 0.875rem
  line-height: 1.125rem
  font-weight: 700

  .stepper-step
    color: $gray-3
    transition: color 300ms, background-color 300ms
    display: flex
    align-items: center

    .stepper-button
      +button-unset
      display: flex
      align-items: center
      curosr: pointer
      color: $gray-3

      &:disabled
        cursor: default

    .step-number
      border: 1px solid $gray-3
      color: $gray-3
      background-color: $white
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      height: 2rem
      width: 2rem
      margin-right: 12px
      transition: color 300ms, background-color 300ms

    &.current
      color: $gray-4
      .step-number
        border-color: $gray-4
        background-color: $gray-4
        color: $white
      .stepper-button
        color: $gray-4
