@import 'constants'

@keyframes stepping-1
  0%
    opacity: 1

  33%
    opacity: 0

  66%
    opacity: 0

  100%
    opacity: 1

@keyframes stepping-2
  0%
    opacity: 0

  33%
    opacity: 1

  66%
    opacity: 0

  100%
    opacity: 0

@keyframes stepping-3
  0%
    opacity: 0

  33%
    opacity: 0

  66%
    opacity: 1

  100%
    opacity: 0

.AuxNav
  display: flex
  flex-direction: column
  width: 13rem
  padding-left: 0.25rem
  padding-top: 0.25rem

.AuxButton
  font-style: normal
  font-weight: bold
  font-size: 14px
  line-height: 24px
  display: flex
  padding: 0.5rem
  transition: color 200ms
  &.hasSubitems
    padding-bottom: 0

  &, &:link, &:active, &:visited
    color: $gray-4
    text-decoration: none

  svg
    margin-right: 0.75rem

    path
      fill: $gray-4
      transition: fill 200ms

  &:hover
    color: $black
    text-decoration: none
    transition: color 50ms

    & svg path
      fill: $black
      transition: fill 50ms

  &.active
    color: $primary

    & svg path
      fill: $primary

  &.isDisabled
    color: $gray-3
    cursor: no-drop

    & svg path
      fill: $gray-3

.AuxSeparator
  height: 2rem

.MobileAppLinks
  margin-top: 1.5rem
  margin-left: 2.75rem
  margin-left: 0.75rem
  margin-right: 1rem
  font-size: 0.875rem
  font-size: 0.75rem
  line-height: 1.25rem

  color: $gray-4

  a, a:link, a:active, a:visited
    color: $black

  a:hover
    color: $primary

  .animated
    .red, .green, .blue
      position: absolute
      margin-left: 0.25rem

    .red
      animation: stepping-1 2000ms infinite
    .green
      animation: stepping-2 2000ms infinite
    .blue
      animation: stepping-3 2000ms infinite
