
@import '../../constants.sass'

.MultiToggle
    background: $white
    display: flex
    border: 1px solid $gray-2
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.03)
    border-radius: 16px
    padding: 0

    & .Toggle
        white-space: nowrap
        border-radius: 25px
        border: 0
        flex: 1
        padding: 0 16px
        cursor: pointer
        font-weight: 700
        font-size: 14px
        line-height: 32px
        text-align: center
        color: black
        background: $white

        &.Active
            background: $primary
            color: $white

        &.Disabled
            background: $gray-2
            cursor: not-allowed
            opacity: .6
