@import '../../../../components/constants'
@import '../../../../utils'

.RoomSettingsDetails
  padding: toRem(32)
  background: $white

  .Description
    display: flex
    gap: toRem(48)
    margin-bottom: toRem(24)

    div
      font-size: toRem(14)
      line-height: toRem(24)
      max-width: toRem(387)

    svg
      flex: 1 0 auto
      height: toRem(64)
      width: toRem(64)

      path
        fill: $gray-2


  .Accordion
    max-width: toRem(424)

    &__content
      margin-top: toRem(24)

    .Steps
      display: flex
      flex-direction: column
      gap: toRem(12)

      .Step
        .StepHead
          display: flex
          align-items: center

          .StepIcon
            display: flex
            margin-right: toRem(8)

            &.Uncompleted
              svg
                padding: toRem(2)

            &.Loading
              height: auto
              background: none


            svg
              width: toRem(24)
              height: toRem(24)

          .StepTitle
            font-size: toRem(14)
            font-weight: 700
            line-height: toRem(24)

            &.Completed
              color: $black
              pointer-events: none

        .StepBody
          font-size: toRem(12)
          line-height: toRem(18)
          color: $gray-4
          padding-left: toRem(32)
