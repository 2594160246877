@import ../../constants

.ConfirmationModal.ModalForm
    width: 28rem

    .top-bar
        border-bottom: 1px solid $gray-2

    .hint
        font-size: 16px
        color: $black
        font-weight: 400
        padding-bottom: 0.0625rem
        text-align: center
        padding-top: 2rem

.ConfirmationMenu.ModalForm
    background: white
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    h1
        margin-bottom: 0
        margin-top: 0

    .top-bar
        border-bottom: 1px solid $gray-2
        width: 85%
        display: flex
        justify-content: center
        align-items: center
        padding-bottom: 2rem

    .hint
        font-size: 1rem
        color: $gray-5
        font-weight: 300
        padding-top: 1rem
        margin-bottom: 0
        text-align: center

    .actions
        border-top: none
        display: flex
        flex-direction: column-reverse
        align-items: center
        width: 100%

        .Button
            width: 100%
            margin-bottom: 20px

        .secondary
            background: none
            color: $black
