@import ../../constants

.Divider
    height: 1px
    width: 100%

    &.margin
        margin: 32px 0

    &.gray-1
        background-color: $gray-1

    &.gray-2
        background-color: $gray-2

    &.gray-3
        background-color: $gray-3
