@import ../constants

.DeskPicker
  flex-grow: 1
  display: flex
  flex-direction: column

  .MapContainer
    flex-grow: 1

    .Map.map
      opacity: 0

      &.visible
        opacity: 1

  .no-floors
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-grow: 1
    color: $gray-3

  .choose-another-building
    position: fixed
    left: 1rem
    right: 1rem
    bottom: 1rem
