@import '../../components/constants'

.VisitorLoginForm
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

    .Card
        padding: 2rem
        padding-top: 1.25rem
        width: 21rem

    .Label
        display: block
        font-style: normal
        font-weight: bold
        font-size: 14px
        line-height: 19px

        color: $gray-4

        margin-bottom: 1rem

    .actions
        display: flex
        align-items: center
        justify-content: flex-start

        .Button:first-child
            margin-right: 1rem

        .Button
            +shadow-1
