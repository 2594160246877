@import constants

.access-activity-table
  table
    border-collapse: collapse
    width: 100%
    text-align: left

    th, td
      font-size: 10px
      line-height: 2em
      padding-left: .5rem
      vertical-align: top
    th:last-child, td:last-child
      padding-right: .5rem

    thead
      th
        vertical-align: top
        font-weight: 400
        background-color: $gray-1
        white-space: nowrap
        .table-title
          font-weight: 700
          font-size: 12px
          line-height: 1.5rem

    tbody
      tr:nth-child(even)
        background-color: $gray-1
      tr:nth-child(odd)
        background-color: $white
