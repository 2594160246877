@import ../../../components/constants

@keyframes gradient-wipe
  0%
    background-position: 0vw 0
  100%
    background-position: 200vw 0

@keyframes spin
  100%
    transform: rotate(360deg)

.Reservations
  display: flex
  flex-direction: column

  background: white

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .list
      flex-grow: 1
      overflow-y: auto
      height: 0px

      .refresh
        width: 2rem
        height: 2rem
        background-color: $white
        border-radius: 50%
        padding: 0.2rem
        animation: spin 1s infinite linear

      .reservations, .no-reservations
        padding: 0 1rem
        margin-bottom: 7rem

        .load-more
          cursor: pointer
          text-align: center
          margin-top: 1rem
          margin-bottom: 1rem
          padding: 0.5rem
          background-color: $white
          position: relative

          .Loader
            display: flex
            justify-content: center
            align-items: center

            svg
              width: 1.5rem
              height: 1.5rem

      .no-reservations
        text-align: center
        padding: 0 1rem
        margin-top: 2rem
        color: $gray-3

        .info-text
          font-size: $font-size-small
          max-width: 23rem
          padding:
            left: 1rem
            right: 1rem
          margin:
            left: auto
            right: auto

        .info-icon
          svg
            width: 4rem
            height: 4rem

          svg path
            fill: $gray-3

        .load-more
          color: $black

  .check-in
    display: flex
    margin: 0.5rem 0.3rem

    div
      margin: 0 0.2rem

    .text
      flex-grow: 1
      padding: 0 0.2rem

      .main
        font-weight: bold
        margin-bottom: 0.3rem

      .sub
        font-size: $font-size-small
        color: $gray-5

.Reservations

  .LaunchButton
    position: relative

  .LaunchButton:after
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 3px
    background-image: linear-gradient(to right, mix(blue, $gray-2, 33%), $gray-2, mix(blue, $gray-2, 33%))
    bottom: -4px
    z-index: 2
    opacity: 0
    transition: opacity 200ms
    pointer-events: none

  &.isLoading .big-loader
    .Loader
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    .LaunchButton:after
      animation: gradient-wipe 1600ms linear infinite
      opacity: 1
      transition: opacity 800ms
