@import '../../components/constants'

.ShareableFloorPlan
  --headerHeight: 86px
  --siebarWidth: 27.5rem
  height: var(--view-height-safe)

  &.isCentered
    display: flex
    justify-content: center
    align-items: center

  .error-text
    text-align: center
    font-weight: 500
    font-size: 32px
    line-height: 44px
    color: $black
    width: 80%

  .strong
    font-weight: 700

  .wrapper
    display: flex
    height: 100%
    background-color: $gray-4
    flex-direction: column

    .content
      flex: 1 0 auto
      display: flex
      height: calc(var(--view-height-safe) - var(--headerHeight))

      .you-are-here
        z-index: 3

    .sidebar
      flex: 0 0 auto
      width: 30vw
      max-width: var(--siebarWidth)
      height: calc(var(--view-height-safe) - var(--headerHeight))
      background-color: $white
      overflow: hidden
      padding: 0 1rem .5rem 1rem
      min-width: 14.5rem
      possition: relative

      &::after
        position: absolute
        content: ''
        height: 53px
        max-width: var(--siebarWidth)
        width: 100%
        bottom: 0px
        left: 0
        background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)

      .stale
        margin-top: 1.5rem
        font-size: 14px
        line-height: 1.5rem

      .desk-list
        max-height: none
        padding-bottom: 3.5rem

      @media (min-width: 1000px)
        padding: 0 3rem .5rem 3rem

    .floor-map
      width: calc(100vw - min(max(14.5rem, 30vw), var(--siebarWidth)))
      height: 100%
      padding: .5rem
      background-color: $gray-1
