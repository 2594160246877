@import ../constants

.PinField
  input
    +inner-shadow-2

    width: 100%
    padding: 8px 12px

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black
    background-color: $gray-1
    border: 0

    transition: background-color 200ms
    cursor: pointer

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms
      outline: none
