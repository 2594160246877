@import ../constants

.DeskInfo

  .info
    display: flex

    .text
      flex-grow: 1
      padding: 0.8rem
      overflow: hidden

      .main, .sub, .status
        margin-bottom: 0.4rem

      .main
        font-weight: bold
        text-overflow: ellipsis
        overflow: hidden

      .sub
        font-size: small
        color: $gray-5

      .status
        display: flex
        flex-wrap: wrap
        overflow: hidden

        span
          padding: 0.1rem 0.5rem
          font-size: small
          color: black
          background: $gray-text-background
          overflow: hidden
          text-overflow: ellipsis

      .amenities
        font-size: small
        color: $gray-5
        font-weight: 400
        line-height: 1.25
        display: flex
        flex-wrap: wrap
        overflow: hidden

        span
          overflow: hidden
          text-overflow: ellipsis
          margin-right: .25rem

  .reservations
    margin-top: 0.5rem

    .item
      display: flex
      border-top: 1px solid $gray-3
      padding: 0.5rem 0.8rem

      .check
        width: 1.3rem

        svg
          width: 0.6rem
          height: 0.6rem

        .out path
          fill: $gray-5

      .name
        flex-grow: 1
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      .time
        margin-left: 0.5rem
        white-space: nowrap
