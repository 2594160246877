@import ../../components/constants
@import ../../utils

.Health
  width: 100%

  .PageHeader
    padding: 1.5rem 2rem
    background: #EDEFF2
    width: 100%

    .Subtext
      color: $gray-4
      font-size: toRem(14)
      font-weight: 500

  .Filters
    background-color: $white
    padding: 2rem

    .btn-Bulk
      .Control
        line-height: 1.125rem
        padding: 0.625rem 1rem
        background-color: $gray-2
      svg
        height: toRem(18)

  .health-settings-header
    &__controls
      display: flex
      align-items: center
      gap: 16px

    &__content
      margin-top: 16px

  .Intro
    display: flex
    align-items: center
    width: 100%
    justify-content: space-between

    .Subheading
      max-width: 60%

  .row
    display: flex
    width: 100%
    justify-content: space-between
    margin-bottom: 2em

    .FieldWrapper
      margin-bottom: 0

    .Switch
      margin-bottom: 1em

    .Button
      min-width: 10%
      justify-content: center

  .Label.CheckboxLabel
    font-weight: 400

  .PageForm
    .Intro
      font-weight: 600

  .indicator
    height: .6rem
    width: .6rem
    border-radius: .6rem
    background-color: $valid
    margin-right: .5rem

    &.inactive
      background-color: $danger

  .StatusCell
    display: flex
    align-items: center

.EmptyTable
  padding: toRem(100)
  display: flex
  flex-direction: column
  place-items: center
  svg
    path
      fill: $gray-4 !important
