@import '../../../components/constants'
@import '../../../utils'

.AssetsSettings
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start
	width: 100%

	.Filters
		.Dropdown .control, .Search input
			border: none
			box-shadow: none

	> .asset-type-list
		width: 100%

		> .asset-card
			&:not(:last-child)
				margin-bottom: toRem(32)

	.loading
		width: 100%
		display: flex
		justify-content: center

.Card.asset-card
	min-width: toRem(180)
	padding: 0
	width: 100%
	box-shadow: none
	border-radius: toRem(2) toRem(2) 0 0

	.Loader
		padding-bottom: toRem(32)

	.card-head
		padding: toRem(32) toRem(32) 0

	&.no-assets
		.card-head
			display: flex
			flex-direction: column
			justify-content: center
			align-items: center
			padding: toRem(48)

			div
				font-size: toRem(14)
				line-height: toRem(24)
				color: $gray-4

			svg
				width: toRem(48)
				height: toRem(48)
				margin-bottom: toRem(12)

				path
					fill: $gray-4


		.card-foot
			text-align: center
			background: #FEFFDE
			width: 100%
			padding: toRem(48) toRem(64)
			border-radius: 0 0 toRem(2) toRem(2)

			.text-link
				color: $primary
				cursor: pointer

	.Pagination
		gap: toRem(90)

		.summary
			margin: 0
			color: $gray-4
			min-width: auto
			font-size: toRem(12)
