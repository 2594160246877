
@import ./../../constants

.AsyncAutocomplete
  width: 100%

  .Wrapper
    position: relative
    width: 100%

  .SuggestionList
    position: absolute
    background-color: $white
    width: 100%
    z-index: 1
    border-radius: 3px
    margin-top: 0.5rem
    +shadow-1

    .SuggestionItem
      padding: 0.6rem 1rem
      cursor: pointer
      color: $gray-4

      &:hover
        color: $primary
