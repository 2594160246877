@import ../constants

.mobile-wrapper
	padding-top: var(--view-padding-top-safe)
	background-color: var(--background-mobile)

	.mobile-background
		+flex-center
		background-color: var(--background-mobile)
		flex-direction: column
		align-items: stretch

	@media (prefers-color-scheme: dark)
		background-color: $black
