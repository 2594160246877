@import '../../constants.sass'

.MultiActionButton
	position: relative
	min-width: 150px
	cursor: pointer

	&.disabled
		cursor: auto
		opacity: 0.5
		pointer-events: none

	.Control
		cursor: pointer
		background-color: $white
		padding: 8px 16px
		font-weight: 700
		font-size: 14px
		line-height: 32px
		display: flex
		align-items: center
		text-align: center
		letter-spacing: 0.12em
		text-transform: uppercase
		justify-content: space-around

		svg
			margin-left: 0.5rem

		svg path
			fill: $black

	.Content
		position: absolute
		z-index: 10
		top: 100%
		left: 0
		width: 100%
		background: $white
		box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.06)
		border-radius: 0px 4px 4px 4px
		display: flex
		flex-direction: column
		align-items: flex-start
		padding: 12px 20px

		.Option
			width: 100%

			.Label
				color: $gray-4
				font-weight: 700
				font-size: 14px
				line-height: 19px
				margin: 3px 0

			&:hover .Label
				color: $primary

	&.isSmall .Control
		font-size: 12px
		line-height: 14px
		padding: 5px 14px

	&.isSmall .Content
		padding: 0

		.Option .Label
			font-size: 12px
			line-height: 14px
			padding: 6px 14px