@import ./../../constants

.GoogleMap
  height: 12.5rem
  width: 100%
  background: $gray-3
  margin-bottom: 2rem

  .GoogleMap__pin
    height: 40px
    width: 40px
    path
      fill: $primary
