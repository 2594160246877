@import ../constants

.DateTimePicker
  padding-bottom: calc(1rem + var(--view-padding-bottom-safe))
  background: white

  .picker-content

    .section-head
      display: flex
      flex-direction: column

    .section
      display: flex
      flex-direction: column
      padding-left: 1rem
      border-top: 1px solid $gray-2

    .section-foot
      display: flex
      flex-direction: column
      padding: 0 1rem
