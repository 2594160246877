@import '../../constants'

.Status
  display: flex
  align-items: center

  &::before
    content: ' '
    width: .25rem
    height: .25rem
    display: block
    background-color: $valid
    border-radius: 50%
    margin-right: .5rem

  &.big
    &::before
      width: .5rem
      height: .5rem

  &.invalid
    &::before
      background-color: $danger

  &.warning
    &::before
      background-color: $warning
