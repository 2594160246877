@import ../../../components/constants

.Start
  display: flex
  flex-direction: column
  background: var(--background-mobile)
  text-align: center

  .body
    flex-grow: 1
    padding-left: 1.5rem
    padding-right: 1.5rem
    padding-top: var(--view-padding-top-safe)

    .logo
      margin: 3.8rem 0 2rem 0
      @media screen and (min-height: 700px)
        margin-bottom: 3rem

      img
        width: 8rem
        height: 8rem

    h2
      font-size: $font-size-title
      margin: 0 0 0.5rem 0

    p
      font-size: $font-size-normal
      font-weight: 100
      margin: 0 0 1rem

      @media screen and (min-height: 700px)
        margin-bottom: 3rem

    .input-error
      margin-top: 0.5rem
      color: $danger
      text-align: left
      font-weight: bold
      font-size: $font-size-small

    .confirmation
      color: $gray-4
      text-align: left
      margin-top: 1rem

      .NewCheckbox
        .Label.CheckboxLabel
          font-weight: 400

  .action
    padding: 1rem 1.5rem calc(1rem + var(--view-padding-bottom-safe)) 1.5rem

    .app-switch
      color: $gray-4
      margin: 1rem 0
      gap: 0.25rem

      .switch
        color: $primary
        cursor: pointer

    .cant-login-button
      width: 100%
      margin: 1.5rem 0
      user-select: none
