@import ../constants

.NameField
  width: 100%

  input
    width: 100%
    background: $gray-1
    border: 0

    padding: 8px 12px

    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px

    font-feature-settings: 'tnum' on, 'onum' on

    color: $black

    &:hover, &:focus
      background-color: mix($gray-1, $gray-2, 66%)
      transition: background-color 100ms

  input[type=text]
    cursor: text

  input:focus
    outline: none

  input[disabled]
    color: $gray-4

  .wrapper
    width: 100%
    position: relative

    svg.lock path
      fill: $gray-4

    svg.lock
      position: absolute
      top: 50%
      right: 0
      transform: translate(-50%, -50%)
      margin-right: 3px

  .wrapper:hover
    svg.lock path
      fill: $black

  p
    color: $gray-3
