@import ../constants

.SuggestedDesk
  background: $white
  padding: 1rem

  &:not(:last-child)
    margin-bottom: 1rem

  .desk-name
    padding-bottom: 0.75rem

  .desk-type
    font-size: $font-size-small
    color: $gray-5
    padding-bottom: 0.25rem

  hr
    border: none
    height: 1px
    background-color: $black
    margin-bottom: 1rem

  .desk-timeslots

    .desk-timeslot
      display: flex
      margin-bottom: 0.75rem

      &:last-child
        margin-bottom: 0

      .time
        font-size: $font-size-small
        padding-right: 1rem

      .name
        font-size: $font-size-small
        font-weight: 700

      .spacer
        flex-grow: 1

      .action
        cursor: pointer
        text-transform: uppercase
        color: $primary
        font-weight: 700

        &.isBooking
          color: $gray-4

        &.isDisabled
          color: $gray-5

  .desk-custom-time-button
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    margin-top: 1rem

    svg path
      fill: $black

    .custom-time-label
      text-transform: uppercase
      padding-left: 0.75rem

  .desk-custom-time-inputs
    margin-top: 1rem

    .inputs
      display: flex

      input
        width: 100%
        padding: 0.3rem 0.5rem
        border: 1px solid $gray-2
        outline: none
        background: white
        -webkit-appearance: none
        border-radius: 0
        font-size: $font-size-normal
        height: 40px

      input:focus
        background: $blue-text-background
        border: 1px solid $primary

      .from
        flex-basis: 45%
        display: flex

      .icon
        flex-basis: 10%
        display: flex
        justify-content: center
        align-items: center
        color: $gray-3

      .to
        flex-basis: 45%
        display: flex

    .book-button
      margin-top: 1rem
