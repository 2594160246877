@import '../../../components/constants'
@import '../../../utils'

.asset-item
	&:not(:last-child)
		border-bottom: 1px solid $gray-2

	padding: toRem(16) toRem(32) toRem(16) 0

	&.inactive
		opacity: .5

	.asset-item-button
		+button-unset
		display: flex
		flex-direction: row
		justify-content: flex-start
		align-items: flex-start
		text-align: start
		font-size: toRem(14)
		line-height: toRem(24)
		cursor: pointer

		.number
			text-align: center
			width: toRem(24)
			color: $gray-4

		.name
			color: $primary
			font-size: toRem(16)
			font-weight: 700
			flex-shrink: 0
			margin-left: toRem(24)
			min-width: toRem(120)

		.description
			color: $gray-4
			margin-left: toRem(32)
