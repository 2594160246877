@import ../../../../components/constants

.DeskLayout

  .desks
    width: 100%
    display: flex

    .floor-plan
      flex-grow: 1
      height: calc(var(--view-height-safe) - 23.55rem)
      padding: 0
      display: flex
      justify-content: center
      align-items: stretch
      width: 100%

      & > *
        width: 100%

      p
        line-height: 160%

      &.missing
        min-height: 12rem
        display: flex
        justify-content: center
        align-items: center
        text-align: center

    .options
      min-width: 16rem
      margin-left: 2rem
      background-color: $white
      height: max-content
      padding: 32px

      &.hidden
        background-color: transparent

      .selected-desks
        display: flex
        align-items: center
        gap: 8px
        color: $gray-4

        svg
          path
            fill: $primary

      .Button
        margin-top: 1.5rem

  .PlanImage
    cursor: crosshair
