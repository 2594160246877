@import './constants'

.NavMenu
  padding: .5rem

  .nav-link
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 24px
    display: flex

    &, &:link, &:active, &:visited
      color: $gray-4
      text-decoration: none

    svg
      margin-right: 0.75rem

      path
        fill: $gray-4
        transition: fill 200ms

    .chevron
      margin-left: auto
      margin-right: 1.5rem

    &:hover
      color: $black
      text-decoration: none
      transition: color 50ms

      & svg path
        fill: $black
        transition: fill 50ms

    &.active
      color: $primary

      & svg path
        fill: $primary
        transition: fill 50ms
      .chevron
        transform: scaleY(-1)
        transition: transform 300ms

    &.isDisabled
      color: $gray-3
      cursor: no-drop

      svg path
      fill: $gray-3
  .sub-nav
    overflow: hidden
  .NavSubItem
    margin-left: 2.3rem
    margin-top: 0.5rem

.NavSubItem
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 20px
  display: flex
  margin-left: 2.8rem
  transition: color 200ms
  letter-spacing: 0.01875rem

  &, &:link, &:active, &:visited
    color: $gray-4
    text-decoration: none

  &:hover
    color: $black
    text-decoration: none
    transition: color 50ms

  &.active
    color: $black
    font-weight: 700
    letter-spacing: 0

  &.isDisabled
    color: $gray-3
    cursor: no-drop
