@import "../../../components/constants"

.VisitorSettingsUserForm
	.Card
		min-width: 42rem
		padding: 2rem

	.SettingsGroup
		padding-left: calc(40px + 1rem)
		width: 100%
		& .Row
			display: flex

	.Subtext
		color: $gray-4
		font-size: 12px
		font-weight: 400
		&.mw-50
			max-width: 50%

	.fields
		width: 100%
		display: flex
		flex-wrap: wrap
		justify-content: space-between

		.FieldWrapper.field-width-50
			width: calc(50% - 1rem)

		.StoreIcons
			svg
				margin-right: 1rem
