@import ../constants

.EventRow
  &.canBeActive
    border-left: 4px solid $primary

  .actions-row .action
    color: $primary

  &.isActive
    .actions-row .action
      color: $white

.EventActions
  .actions-row
    display: flex
    background: white
    border-bottom: 1px solid $gray-2
    padding: 0.8rem 1rem
    color: $gray-5

    &.small
      background: $black
      border: none
      padding: 0.75rem 0 0.25rem 0
      color: $white

    .action
      font-weight: bold
      text-transform: uppercase
      cursor: pointer
      margin-right: 1.5rem