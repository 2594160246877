@import constants

.Header
  background: $white
  color: $gray-3
  +shadow-1

  +flex-center

  // position: sticky
  // top: 0

  .row
    height: 4rem
    width: 100%

    +flex-center

  &:not(.isConstrained) .row
    padding:
      left: 1rem
      right: 1rem

  &.isConstrained .row
    max-width: 73rem

  .logo
    +flex-center
    justify-content: flex-start
    width: 10rem
    margin:
      left: 1rem
      right: 1rem

    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 25px
    text-transform: lowercase
    color: $black
    cursor: pointer

    img
      height: auto
      margin-left: 0.33rem
      width: 5.5rem
      // height: 64px

    &-whitelabel

      img
        width: 4.85rem

  .nav
    margin:
      left: 1rem
      right: 1rem

    a
      margin:
        left: 1rem
        right: 1rem

      font-style: normal
      font-weight: bold
      font-size: 14px
      line-height: 64px

      text-decoration: none

      color: $gray-3

      transition: color 200ms

      &.active, &:active
        color: $black

      &:hover
        color: $primary
        transition: color 50ms

  .stretch
    flex-grow: 1

  .date-time
    +flex-center

    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 64px

    display: flex
    align-items: center
    font-feature-settings: 'pnum' on, 'onum' on

    div
      margin:
        left: 1rem
        right: 1rem

  .user
    display: flex
    align-items: center

    cursor: pointer

    text-transform: capitalize

    margin:
      left: 1rem
      right: 1rem

    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 64px

    span.name
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 18rem

    &, &:active, &:visited
      color: $black
      text-decoration: none

    &:hover
      color: $primary

  .Avatar
    margin-right: 0.5rem
