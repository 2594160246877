.department-name
  font-weight: 700
  font-size: 15px
  line-height: 2rem
  margin-bottom: .5rem
  margin-top: 1.5rem
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

  @media (min-width: 768px)
    font-size: 20px
