@import ../components/constants
.RouteView.HealthScreening
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 2rem

    .FieldWrapper
        align-items: center
        text-align: center

.HealthSurveyMobile
    display: flex
    flex-direction: column
    background-color: $white

    .Heading, .Intro, .HealthSurveyForm
        padding-left: 1rem
        padding-right: 1rem

    .HealthSurveyForm
        flex-grow: 1
        height: 0px
        overflow-y: auto
        padding-bottom: 2rem

    .HealthMiddle
        flex-grow: 1
        height: 0px
        overflow-y: auto
        padding:
            left: 1rem
            bottom: 2rem
            right: 1rem
        display: flex
        flex-direction: column
        align-items: center
        text-align: center

        form
            width: 100%

            .FieldWrapper
                align-items: center
                text-align: center

            .Button.mobile-action
                margin-top: 2rem

.HealthScreening
    .Screen
        width: 100%
        margin: 0
        padding-left: 1rem
        padding-right: 1rem

    &.isConstrained
        max-width: 42rem !important

.HealthSurveyResult
    .Intro
        text-align: center

.App .RouteView .View.HealthSurveyResult
    align-items: center
