@import constants

.Search
  position: relative

  input
    display: flex
    justify-content: center
    align-items: center
    padding: 6px 12px 7px
    width: 100%

    background: $white
    border: 1px solid $gray-2
    box-sizing: border-box

    +inner-shadow-1

    border-radius: 16px

    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 19px

    font-feature-settings: 'pnum' on, 'lnum' on

    color: $black

    transition: border-color 200ms

  input:focus
    outline: none
    border-color: mix($gray-2, $gray-3)

  .clear, .loupe
    position: absolute
    top: 0
    right: 0
    width: 1.5rem
    height: 1.5rem

    >svg path
      fill: $primary

  .clear
    transform: translate(-16px, 5px)

  .loupe
    transform: translate(-12px, 5px)

  .clear:hover
    cursor: pointer
    path
      fill: $black
