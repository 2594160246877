@import ../../components/constants
@import ../../utils

.Presence
  display: flex
  flex-direction: column

  .body
    display: flex
    flex-direction: column
    flex-grow: 1
    background: var(--background-mobile)

    .search-info
      border-bottom: 1px solid $gray-2
      padding: 1rem 1rem

      h3
        margin: 0.5rem 0 0.2rem 0
        font-size: $font-size-big
        font-weight: 600

      .present-number
        color: $primary

    .presents
      flex-grow: 1
      position: relative

      .scroller
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        overflow: scroll

    .no-presents
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      flex-grow: 1
      color: $gray-3

      svg
        width: 4rem
        height: 4rem

      svg path
        fill: $gray-3

.Present
  display: flex
  border-bottom: 1px solid $gray-2
  padding: 1rem 1rem

  .check
    width: 1rem
    padding-top: 0.5rem

    .check-indicator
      width: 0.5rem
      height: 0.5rem
      border-radius: 50%
      &.in
        background-color: $primary

      &.out
        border: 1px solid $gray-4

  .person-info
    flex-grow: 1

    .name
      font-weight: 600

    .time
      color: $gray-4
      font-size: $font-size-small
      margin-top: 0.25rem

    .start::after
      display: inline-block
      content: ''
      width: 0
      height: 0
      border-top: 4px solid transparent
      border-bottom: 4px solid transparent

      border-left: 4px solid $gray-4
      margin-left: toRem(6)
      margin-right: toRem(6)

  .location
    display: flex
    justify-content: flex-end
    align-items: center
    width: 3rem
    text-align: right

    &-icon
      cursor: pointer
