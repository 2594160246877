@import ../constants

.DeskForm
  width: 28rem

  .DepartmentsMultiField, .DeskEnableField
    width: 100%

    p
      color: $gray-3
